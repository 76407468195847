import { Action } from '@ngrx/store';
export const SET_NATIFICATION_SETTINGS = '[NATIFICATION] SET NATIFICATION SETTINGS';
export const SET_NATIFICATION_SETTINGS_SUCCESS = '[NATIFICATION] SET NATIFICATION SETTINGS SUCCESS';
export const GET_NATIFICATION_SETTINGS = '[NATIFICATION] GET NATIFICATION SETTINGS';
export const GET_NATIFICATION_SETTINGS_SUCCESS = '[NATIFICATION] GET NATIFICATION SETTINGS SUCCESS';
export const GET_NATIFICATION_FAIL = '[NATIFICATION] GET NATIFICATION FAIL';

export const GET_NATIFICATION = '[NATIFICATION] GET NATIFICATION';
export const GET_NATIFICATION_SUCCESS = '[NATIFICATION] GET NATIFICATION SUCCESS';

export class SetNatificationSettings implements Action {
    readonly type = SET_NATIFICATION_SETTINGS;

    constructor(public payload: any)
    {
    }
}

export class SetNatificationSettingsSuccess implements Action {
    readonly type = SET_NATIFICATION_SETTINGS_SUCCESS;

    constructor()
    {
    }
}

export class GetNatificationSettings implements Action {
    readonly type = GET_NATIFICATION_SETTINGS;

    constructor()
    {
    }
}


export class GetNatificationSettingsSuccess implements Action {
    readonly type = GET_NATIFICATION_SETTINGS_SUCCESS;

    constructor(public payload: any)
    {
    }
}

export class GetNatificationFail implements Action {
    readonly type = GET_NATIFICATION_FAIL;

    constructor(public payload: any)
    {
    }
}

export class GetNatification implements Action {
    readonly type = GET_NATIFICATION;

    constructor()
    {
    }
}

export class GetNatificationSuccess implements Action {
    readonly type = GET_NATIFICATION_SUCCESS;

    constructor(public payload: any)
    {
    }
}




export type NatifiationActionsAll
    = SetNatificationSettings
    | SetNatificationSettingsSuccess
    | GetNatificationSettings
    | GetNatificationSettingsSuccess
    | GetNatification
    | GetNatificationSuccess
    | GetNatificationFail;