import { createSelector } from '@ngrx/store';
import { NatificationState } from '../reducers/natification.reducer';
import { getNatificationState } from '../reducers';


export const getFullNatifications = createSelector(
    getNatificationState,
    (state: NatificationState) => state
);

export const getNatifications = createSelector(
    getFullNatifications,
    (state: NatificationState) => state.natification
)
