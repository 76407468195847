import { HttpClient } from '@angular/common/http';
import { Component, Inject, Injectable } from '@angular/core';
import {
    MatSnackBar,
    MatSnackBarConfig,
    MatSnackBarRef,
    MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';
import { LoginService } from 'app/main/pages/authentication/login/login.service';
import { environment } from 'environments/environment.prod';

/**
 * SPECIALIZED SNACK-BAR COMPONENT FOR RE-SEND EMAIL.
 */
@Component({
    selector: 'snack-bar-component-example-snack',
    template: `<div class="mat-simple-snackbar ng-star-inserted">
        <span>Необходимо подтвердить ваш аккаунт. Отправить письмо подтверждения повторно?</span>
        <div class="mat-simple-snackbar-action ng-star-inserted" style="text-align: center;">
            <button mat-button="" class="mat-button mat-button-base" (click)="cancel()">
                <span class="mat-button-wrapper">Закрыть</span>
            </button>
            <button mat-button="" class="mat-button mat-button-base" (click)="doAction()">
                <span class="mat-button-wrapper"> Отправить </span>
            </button>
        </div>
    </div>`,
    styles: [``],
})
export class CustomSnackBarComponent {
    private urlApi: string;
    private config: MatSnackBarConfig = {
        duration: environment.timeoutSnackBar,
        verticalPosition: 'bottom',
    };

    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data,
        private http: HttpClient,
        private snackBar: MatSnackBar
    ) {
        this.urlApi = environment.urlApi;
    }

    doAction() {
        const body = {
            email: this.data.email,
        };
        this.http.post(this.urlApi + '/resend-confirmation-email/', body).subscribe(
            (res: any) => {
                if (res.result) {
                    this.snackBar.open(
                        'Успешно отправлено. Проверьте ваш почтовый ящик.',
                        'Закрыть',
                        this.config
                    );
                }
            },
            (err) => {
                // error handling
            }
        );
        this.data.preClose();
    }

    cancel() {
        this.data.preClose();
    }
}

@Injectable()
export class SnackBarService {
    private config: MatSnackBarConfig = {
        duration: environment.timeoutSnackBar,
        verticalPosition: 'bottom',
    };
    private dismiss: MatSnackBarRef<any>;

    constructor(private snackBar: MatSnackBar) {}

    openSnackBar(message: string, action?: string, requestBody?: any): void {
        if (message === 'Need confirm account') {
            this.snackBar.openFromComponent(CustomSnackBarComponent, {
                data: {
                    preClose: () => {
                        this.snackBar.dismiss();
                    },
                    email: requestBody.email,
                },
                // duration: 5 * 1003330,
            });
        } else {
            if (!action) {
                action = 'Закрыть';
            }
            this.dismiss = this.snackBar.open(message, action, this.config);
        }
    }
}
