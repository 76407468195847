import { Component, OnInit, Inject, ElementRef, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { NatificationService } from 'app/main/apps/profile/notification/natification.service';
import { LocalStorageService } from 'app/shared/services/local-storage.service';

export enum ENatifType {
    danger = 'Ошибка',
    default = 'Уведомление',
}

@Component({
    selector: 'app-natification-modal',
    templateUrl: './natification-modal.component.html',
    styleUrls: ['./natification-modal.component.scss'],
})
export class NatificationModalComponent implements OnInit {
    length: number;
    pageSize = 1;
    pageSizeOptions: number[] = [5, 10, 25, 100];
    natifType = ENatifType;
    public notifications;

    isSmall: boolean = false;

    pageEvent: PageEvent;
    isDark: boolean = false;
    hasWidget: boolean = true;
    apiInProgress: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private readonly _storage: LocalStorageService,
        private _elementRef: ElementRef,
        private natificationService: NatificationService
    ) { }

    ngOnInit(): void {
        this.isSmall = window.innerWidth <= 800;
        window.addEventListener('resize', () => {
            this.isSmall = window.innerWidth <= 800;
        });

        this.notifications = this.data.notifications;
        this.length = this.notifications.length;

        if (this._storage.getColorTheme() == 'theme-custom-dark') {
            this.isDark = true;
        } else {
            this.isDark = false;
        }

        var s = document.createElement('script');
        s.id = "vkscript";
        s.type = 'text/javascript';
        s.innerText =
            "VK.Widgets.AllowMessagesFromCommunity(\"vk_allow_messages_from_community\", {height: 30}, 164537430);" +
            "VK.Observer.subscribe(\"widgets.allowMessagesFromCommunity.allowed\", function f(userId) { postMessage('vkWDGT'+':'+userId+':'+'true'); });" +
            "VK.Observer.subscribe(\"widgets.allowMessagesFromCommunity.denied\", function f(userId) { postMessage('vkWDGT'+':'+userId+':'+'false'); });"
            ;
        this._elementRef.nativeElement.appendChild(s);
    }


    /**
    * Listener for the VK result
    * @param e 
    */
    @HostListener('window:message', ['$event'])
    onMessage(e) {
        if (e.data.toString().includes('vkWDGT')  && !this.apiInProgress) {
            this.apiInProgress = true;
            let _data = e.data.toString().split(':');
            this.natificationService.updateVkSettings(_data[1], _data[2]).then(res => {
                this.apiInProgress = false;
            })
        }
    }

    currentNatif() {
        let notification: any = "";
        if (!this.pageEvent) {
            notification = this.notifications[0];
            if (notification.description.toString().includes("notify_button")) {
                this.hasWidget = true;
                let tmp = {
                    description: notification.description.toString().replace('notify_button', ''),
                    type: notification.type
                }
                return tmp;

            } else {
                this.hasWidget = false;
                let tmp = document.getElementById("vk_allow_messages_from_community");
                if (tmp) {
                    tmp.remove();
                }
            }
            return notification;
        }

        notification = this.notifications[this.pageEvent.pageIndex];
        if (notification.description.toString().includes("notify_button")) {
            this.hasWidget = true;
            let tmp = {
                description: notification.description.toString().replace('notify_button', ''),
                type: notification.type
            }
            return tmp;
        } else {
            this.hasWidget = false;
            let tmp = document.getElementById("vk_allow_messages_from_community");
            if (tmp) {
                tmp.remove();
            }
        }

        return notification;
    }
}
