/* tslint:disable:variable-name */
import * as AdminActions from '../actions/admin.action';
import { User } from '../../models/user';
import { Tarif } from 'app/models/tarif';

export interface AdminActionsState {
    users?: { [user_id: number]: User };
    tarifs?: { [id: number]: Tarif };
    usersLoaded: boolean;
    tarifsLoaded: boolean;
    loading: boolean;
    error: string;
}

export const AdminActionsInitialState: AdminActionsState = {
    users: {},
    tarifs: {},
    tarifsLoaded: false,
    usersLoaded: false,
    loading: false,
    error: '',
};

export function AdminActionsReducer(
    state = AdminActionsInitialState,
    action: AdminActions.AdminActionsAll
): AdminActionsState {
    switch (action.type) {
        case AdminActions.GET_USERS:
            return {
                ...state,
                usersLoaded: false,
            };

        case AdminActions.GET_USERS_SUCCESS: {
            const users = action.payload.users.map(
                (_entities: { [user_id: number]: any }, user: any) => {
                    return {
                        ..._entities,
                        [user.user_id]: new User(user),
                    };
                },
                {}
            );
            return {
                ...state,
                usersLoaded: true,
                users,
            };
        }

        case AdminActions.GET_USERS_FAILED:
            return {
                ...state,
                usersLoaded: false,
            };

        case AdminActions.GET_TARIFS:
            return {
                ...state,
                tarifsLoaded: false,
            };

        case AdminActions.GET_TARIFS_SUCCESS: {
            const tarifs = action.payload.map((_entities: { [id: number]: any }, tarif: any) => {
                return {
                    ..._entities,
                    [tarif.id]: new Tarif(tarif),
                };
            }, {});
            return {
                ...state,
                tarifsLoaded: true,
                tarifs,
            };
        }

        case AdminActions.GET_TARIFS_FAILED:
            return {
                ...state,
                tarifsLoaded: false,
            };

        default:
            return state;
    }
}
