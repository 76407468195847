import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment.prod';

declare const InstallTrigger: any;

export class Banner {
    id: number;
    img_url: string;
    title: string;
    url: string;
    view_block: number;
    view_condition: string;
    view_order: number;

    constructor(data?: any) {
        data = data || {};
        this.id = data.id;
        this.img_url = data.img_url;
        this.title = data.title;
        this.url = data.url;
        this.view_block = data.view_block;
        this.view_condition = data.view_condition;
        this.view_order = data.view_order;
    }
}

/**
 * Ads-service that will request data from the backend
 * regarding the banners to be displayed
 */
@Injectable()
export class BannersService {
    private urlApi: string;
    private isChrome: boolean = undefined;

    constructor(private http: HttpClient) {
        this.urlApi = environment.urlApi;
    }

    public checkIfPluginIsPresent() {
            // Теперь игнорируем определение браузера и сразу проверяем наличие плагина.
        this.isChrome = this.checkPlug();
        return this.isChrome;
    }
    
    checkPlug() {
        try {
            var request = new XMLHttpRequest();
            request.open(
                'GET',
                `chrome-extension://ekaadccnkghfijlpajfcfpmmaloleigm/images/vk.png`,
                false
            );
            request.send();
            if (request.status == 200) {
                return true;
            } else {
                return false;
            }
        } catch (e) {
            return false;
        }
    }

    detectBrowser(): string {
        var isChrome = navigator.userAgent.includes('Chrome') &&
                       navigator.userAgent.includes('Safari') &&
                       !navigator.userAgent.includes('Edg');    
        if (isChrome) {
            return 'chrome';
        }
        return '';
    }
    
    
    /**
     * Requests info from the back-end side
     */
    public requestAdsInfo() {
        return this.http.get(this.urlApi + '/banners').toPromise();
    }
}
