import { LOCALE_ID, APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { PaginatorRu } from '@fuse/paginator-ru';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule } from '@fuse/components';
import { fuseConfig } from 'app/fuse-config';
import { AppComponent } from 'app/app.component';
import { AppStoreModule } from 'app/store/store.module';
import { LayoutModule } from 'app/layout/layout.module';
import { JwtModule } from '@auth0/angular-jwt';
import { APIInterceptor } from './interceptors/url.interceptor';
import { registerLocaleData } from '@angular/common';
import { AuthGuard } from './auth.guard';
import { HttpService } from 'app/shared/services/http-service';
import { SnackBarService } from 'app/shared/services/snack-bar.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NatificationModalComponent } from './shared/components/natification-modal/natification-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ConfirmationModalComponent } from './shared/components/confirmation-modal/confirmation-modal.component';
import { RefreshTokenModalComponent } from './shared/components/refresh-token-modal/refresh-token-modal.component';
import { TrialChangeModalComponent } from './shared/components/trial-change-modal/trial-change-modal.component';
import { CleanGroupBackComponent } from './shared/components/clean-group-back/clean-group-back.component';

import localeRu from '@angular/common/locales/ru';
import 'hammerjs';
import * as moment from 'moment';
import * as Sentry from '@sentry/angular';

export function tokenGetter(): string {
    return localStorage.getItem('access_token');
}

const appRoutes: Routes = [
    {
        path: 'apps',
        canActivate: [AuthGuard],
        loadChildren: () => import('./main/apps/apps.module').then((m) => m.AppsModule),
    },
    {
        path: 'pages',
        canActivate: [AuthGuard],
        loadChildren: () => import('./main/pages/pages.module').then((m) => m.PagesModule),
    },
    {
        path: '**',
        redirectTo: '/apps/groups',
        pathMatch: 'full',
    },
];

@NgModule({
    declarations: [
        AppComponent,
        NatificationModalComponent,
        ConfirmationModalComponent,
        RefreshTokenModalComponent,
        TrialChangeModalComponent,
        CleanGroupBackComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),

        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter,
                whitelistedDomains: ['localhost:3005'],
                blacklistedRoutes: ['localhost:3005/api/login'],
            },
        }),

        // Material moment date module
        MatMomentDateModule,
        MatDialogModule,
        // Material
        MatButtonModule,
        MatSnackBarModule,
        MatIconModule,
        MatCardModule,
        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        MatPaginatorModule,

        // App modules
        LayoutModule,
        AppStoreModule,
    ],
    bootstrap: [AppComponent],
    providers: [
        HttpService,
        SnackBarService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: APIInterceptor,
            multi: true,
        },
        { provide: LOCALE_ID, useValue: 'ru-RU' },
        { provide: MatPaginatorIntl, useClass: PaginatorRu },

        // Sentry config
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
    ],
})
export class AppModule {
    constructor() {
        registerLocaleData(localeRu, 'ru');
        moment.locale('ru');
    }
}
