import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import * as NatificationrActions from 'app/store/actions/natification.action';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class NatificationEffects {
    /**
     * Constructor
     *
     */
    public urlApi: string = environment.urlApi;

    constructor(private actions$: Actions, private http: HttpClient) {}

    @Effect()
    getNatificationsSettings: Observable<NatificationrActions.NatifiationActionsAll> = this.actions$.pipe(
        ofType<NatificationrActions.GetNatificationSettings>(
            NatificationrActions.GET_NATIFICATION_SETTINGS
        ),
        switchMap(() => {
            return this.http.get(`${this.urlApi}/notifications/settings`).pipe(
                map((data: any) => {
                    return new NatificationrActions.GetNatificationSettingsSuccess(data.settings);
                }),
                catchError((err) => of(new NatificationrActions.GetNatificationFail(err)))
            );
        })
    );

    @Effect()
    getNatifications: Observable<NatificationrActions.NatifiationActionsAll> = this.actions$.pipe(
        ofType<NatificationrActions.GetNatification>(NatificationrActions.GET_NATIFICATION),
        switchMap(() => {
            return this.http.get(`${this.urlApi}/notifications`).pipe(
                map((data: any) => {
                    return new NatificationrActions.GetNatificationSuccess(data);
                }),
                catchError((err) => of(new NatificationrActions.GetNatificationFail(err)))
            );
        })
    );
}
