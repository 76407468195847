import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { SnackBarService } from 'app/shared/services/snack-bar.service';

@Injectable()
export class HttpService {
    private urlApi: string;

    constructor(private http: HttpClient, private snackBar: SnackBarService) {
        this.urlApi = environment.urlApi;
    }

    getRequest(url: string, silent: boolean = false, message: string = ''): Observable<any> {
        return this.http.get(this.urlApi + url);
    }

    postRequest(
        url: string,
        body: any,
        silent: boolean = false,
        message: string = null
    ): Observable<any> {
        let tmp = Object.assign({}, body);
        return this.http.post(this.urlApi + url, body).pipe(
            catchError((err) => this.handleError(err, tmp)),
            tap(() => {
                if (!silent) {
                    let messageText = 'Запрос успешно выполнен';
                    if (message !== null) {
                        messageText = message;
                    }
                    this.snackBar.openSnackBar(messageText);
                }
            })
        );
    }

    deleteRequest(url: string, silent: boolean = false, message: string = ''): Observable<any> {
        return this.http.delete(this.urlApi + url);
    }

    putRequest(
        url: string,
        body: any,
        silent: boolean = false,
        message: string = ''
    ): Observable<any> {
        return this.http.put(this.urlApi + url, body);
    }

    handleError(error, body?: any): any {
        if (error.error.message !== undefined) {
            this.snackBar.openSnackBar(error.error.message, null, body);
        } else {
            this.snackBar.openSnackBar('Api отвалился');
        }
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            errorMessage = `Error: ${error.error.message}`;
        } else {
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(errorMessage);
    }
}
