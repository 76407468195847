import * as NatificationActions from '../actions/natification.action';
import { INatification } from 'app/models/natification';

export interface NatificationState {
    email: boolean;
    balance: boolean;
    errors: boolean;
    information: boolean;
    vk_notices: boolean;
    loading: boolean;
    site: boolean;
    natification: INatification[] | any;
}

export const NatificationsInitialState: NatificationState = {
    email: false,
    balance: false,
    errors: false,
    information: false,
    vk_notices: false,
    loading: false,
    site: false,
    natification: [],
};

export function NatificationReducer(
    state = NatificationsInitialState,
    action: NatificationActions.NatifiationActionsAll
): NatificationState {
    switch (action.type) {
        case NatificationActions.GET_NATIFICATION:
            return {
                ...state,
                loading: true,
            };

        case NatificationActions.GET_NATIFICATION_SETTINGS_SUCCESS:
            if (action == null || action.payload == null) {
                return {
                    ...state,
                    loading: false,
                };
            }
            const { email, balance, errors, information, vk_notices, site } = action.payload;
            return {
                ...state,
                email,
                balance,
                errors,
                information,
                vk_notices,
                site,
                loading: false,
            };

        case NatificationActions.SET_NATIFICATION_SETTINGS:
            return {
                ...state,
                loading: true,
            };

        case NatificationActions.SET_NATIFICATION_SETTINGS_SUCCESS:
            return {
                ...state,
                loading: false,
            };

        case NatificationActions.GET_NATIFICATION:
            return {
                ...state,
                loading: true,
            };

        case NatificationActions.GET_NATIFICATION_SUCCESS:
            return {
                ...state,
                natification: action.payload,
                loading: false,
            };

        case NatificationActions.GET_NATIFICATION_FAIL:
            return {
                ...state,
                loading: false,
            };

        default:
            return state;
    }
}
