import { Action } from '@ngrx/store';
export const LOGIN_SUCCESS                = '[PROFILE] LOGIN SUCCESS';
export const GET_PROFILE                  = '[PROFILE] GET PROFILE';
export const UPDATE_PROFILE               = '[PROFILE] UPDATE PROFILE';
export const GET_PROFILE_SUCCESS          = '[PROFILE] GET PROFILE SUCCESS';
export const GET_PROFILE_FAILED           = '[PROFILE] GET PROFILE FAILED';
export const GET_ACCOUNT                  = '[PROFILE] GET ACCOUNT';
export const GET_ACCOUNT_SUCCESS          = '[PROFILE] GET ACCOUNT SUCCESS';
export const GET_ACCOUNT_FAILED           = '[PROFILE] GET ACCOUNT FAILED';
export const GET_TARIFF_DISCOUNT          = '[PROFILE] GET TARIFF DISCOUNT';
export const GET_TARIFF_DISCOUNT_SUCCESS  = '[PROFILE] GET TARIFF DISCOUNT SUCCESS';
export const GET_TARIFF_DISCOUNT_FAILED   = '[PROFILE] GET TARIFf DISCOUNT FAILED';
export const GET_TARIFF                   = '[PROFILE] GET TARIFF';
export const GET_TARIFF_SUCCESS           = '[PROFILE] GET TARIFF SUCCESS';
export const GET_TARIFF_FAILED            = '[PROFILE] GET TARIFF FAILED';


/**
 * Update Profile
 */
export class UpdateProfile implements Action
{
    readonly type = UPDATE_PROFILE;

    constructor(public payload: any)
    {
    }
}

/**
 * Get Profile
 */
export class GetProfile implements Action
{
    readonly type = GET_PROFILE;

    constructor()
    {
    }
}

/**
 * Get Profile Success
 */
export class GetProfileSuccess implements Action
{
    readonly type = GET_PROFILE_SUCCESS;

    constructor(public payload: any)
    {
    }
}

/**
 * Get Profile Failed
 */
export class GetProfileFailed implements Action
{
    readonly type = GET_PROFILE_FAILED;

    constructor(public payload: string)
    {
    }
}

/**
 * Get Account
 */
export class GetAccount implements Action
{
    readonly type = GET_ACCOUNT;

    constructor()
    {
    }
}

/**
 * Get Account Success
 */
export class GetAccountSuccess implements Action
{
    readonly type = GET_ACCOUNT_SUCCESS;

    constructor(public payload: any)
    {
    }
}

/**
 * Get Account Failed
 */
export class GetAccountFailed implements Action
{
    readonly type = GET_ACCOUNT_FAILED;

    constructor(public payload: string)
    {
    }
}

export class GetTariffDiscount implements Action {
    readonly type = GET_TARIFF_DISCOUNT;
    constructor(public payload: number){}
}

export class GetTariffDiscountSuccess implements Action {
    readonly type = GET_TARIFF_DISCOUNT_SUCCESS;
    constructor(public payload: any){}
}

export class GetTariffDiscountFailed implements Action {
    readonly type = GET_TARIFF_DISCOUNT_FAILED;
    constructor(public payload: string){}
}

export class GetTariff implements Action {
    readonly type = GET_TARIFF;
    constructor(public payload: number) {}
}

export class GetTariffSuccess implements Action {
    readonly type = GET_TARIFF_SUCCESS;
    constructor(public payload: any) {}
}

export class GetTariffFailed implements Action {
    readonly type = GET_TARIFF_FAILED;
    constructor(public payload: string) {}
}

export class LoginSuccess implements Action {
    readonly type = LOGIN_SUCCESS;
    constructor() {}
}

export type UserActionsAll
    = GetProfile
    |GetProfileSuccess
    |GetProfileFailed
    |GetAccount
    |GetAccountSuccess
    |GetAccountFailed
    |UpdateProfile
    |GetTariffDiscount
    |GetTariffDiscountSuccess
    |GetTariffDiscountFailed
    |GetTariff
    |GetTariffSuccess
    |GetTariffFailed
    |LoginSuccess;
