import { Action } from '@ngrx/store';

export const UPDATE_GROUP = '[GROUPS] UPDATE GROUP';

export const GET_GROUPS         = '[GROUPS] GET GROUPS';
export const GET_GROUPS_SUCCESS = '[GROUPS] GET GROUPS SUCCESS';
export const GET_GROUPS_FAILED  = '[GROUPS] GET GROUPS FAILED';

export const GET_GROUP         = '[GROUPS] GET GROUP';
export const GET_GROUP_SUCCESS = '[GROUPS] GET GROUP SUCCESS';
export const GET_GROUP_FAILED  = '[GROUPS] GET GROUP FAILED';

export const GET_CATEGORIES         = '[CATEGORIES] GET CATEGORIES';
export const GET_CATEGORIES_SUCCESS = '[CATEGORIES] GET CATEGORIES SUCCESS';
export const GET_CATEGORIES_FAILED  = '[CATEGORIES] GET CATEGORIES FAILED';

export const GET_VK_GROUP         = '[GROUPS] GET VK GROUPS';
export const GET_VK_GROUP_SUCCESS = '[GROUPS] GET VK GROUPS SUCCESS';
export const GET_VK_GROUP_FAILED  = '[GROUPS] GET VK GROUPS FAILED';

export const GROUP_ADD         = '[GROUPS] GROUP ADD ';
export const GROUP_ADD_SUCCESS = '[GROUPS] GROUP ADD SUCCESS';
export const GROUP_ADD_FAILED  = '[GROUPS] GROUP ADD FAILED';

export const GROUP_GET_STATISTICS = '[GROUPS] GROUP GET STATISTICS';
export const GROUP_GET_STATISTICS_SUCCESS = '[GROUPS] GROUP GET STATISTICS SUCCESS';


/**
 * Update Group
 */
export class UpdateGroup implements Action
{
    readonly type = UPDATE_GROUP;

    constructor(public payload: any)
    {
    }
}

/**
 * Get Groups
 */
export class GetGroups implements Action
{
    readonly type = GET_GROUPS;

    constructor()
    {
    }
}

/**
 * Get Groups Success
 */
export class GetGroupsSuccess implements Action
{
    readonly type = GET_GROUPS_SUCCESS;

    constructor(public payload: any)
    {
    }
}

/**
 * Get Groups Failed
 */
export class GetGroupsFailed implements Action
{
    readonly type = GET_GROUPS_FAILED;

    constructor(public payload: any)
    {
    }
}

//

/**
 * Get Group
 */
export class GetGroup implements Action
{
    readonly type = GET_GROUP;

    constructor(public id: string)
    {
    }
}

/**
 * Get Group Success
 */
export class GetGroupSuccess implements Action
{
    readonly type = GET_GROUP_SUCCESS;

    constructor(public payload: any)
    {
    }
}

/**
 * Get Group Failed
 */
export class GetGroupFailed implements Action
{
    readonly type = GET_GROUP_FAILED;

    constructor(public payload: string)
    {
    }
}

/**
 * Get Categories
 */
export class GetCategories implements Action
{
    readonly type = GET_CATEGORIES;

    constructor()
    {
    }
}

/**
 * Get Categories Success
 */
export class GetCategoriesSuccess implements Action
{
    readonly type = GET_CATEGORIES_SUCCESS;

    constructor(public payload: any[])
    {
    }
}

/**
 * Get Categories Failed
 */
export class GetCategoriesFailed implements Action
{
    readonly type = GET_CATEGORIES_FAILED;

    constructor(public payload: string)
    {
    }
}

export class GetVKGroup implements Action {
    readonly type = GET_VK_GROUP;
    constructor(){}
}


export class GetVKGroupSuccess implements Action {
    readonly type = GET_VK_GROUP_SUCCESS;
    constructor(public payload: any){}
}

export class GetVKGroupFailed implements Action {
    readonly type = GET_VK_GROUP_FAILED;
    constructor(public payload: string){}
}

export class GroupAdd implements Action {
    readonly type = GROUP_ADD;
    constructor(public payload: any){}
}

export class GroupAddSuccess implements Action {
    readonly type = GROUP_ADD_SUCCESS;
    constructor(){}
}

export class GroupAddFailed implements Action {
    readonly type = GROUP_ADD_FAILED;
    constructor(public payload: string){}
}

export class GroupGetStatistics implements Action {
    readonly type = GROUP_GET_STATISTICS;
}

export class GroupGetStatisticsSuccess implements Action {
    readonly type = GROUP_GET_STATISTICS_SUCCESS;
}
export type GroupActionsAll
    = GetGroups
    |GetGroupsSuccess
    |GetGroupsFailed
    |UpdateGroup
    |GetGroup
    |GetGroupSuccess
    |GetGroupFailed
    |GetCategories
    |GetCategoriesSuccess
    |GetCategoriesFailed
    |GetVKGroup
    |GetVKGroupSuccess
    |GetVKGroupFailed
    |GroupAdd
    |GroupAddSuccess
    |GroupAddFailed
    |GroupGetStatistics
    |GroupGetStatisticsSuccess;
