import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State } from './store/reducers';
import { GetProfile } from 'app/store/actions';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { environment } from 'environments/environment';
@Injectable({
    providedIn: 'root',
})
export class Auth2Service {
    public constructor(private http: HttpClient, private _store: Store<State>) {}

    public urlApi = environment.urlApi;

    clearTokens(): void {
        Cookie.delete('access_token', '/');
        Cookie.delete('refresh_token', '/');
    }

    setJwtToken(jwtToken: string): void {
        Cookie.set('access_token', jwtToken, 1, '/');
    }

    setRefreshToken(refreshToken: string): void {
        Cookie.set('refresh_token', refreshToken, 180, '/');
    }

    getJwtToken(): string {
        return Cookie.get('access_token');
    }

    getRefreshToken(): string {
        return Cookie.get('refresh_token');
    }

    public refreshAccessToken(): Observable<boolean> {
        return this.http
            .post<{ token: string; refresh_token: string }>(`${this.urlApi}/refresh`, {
                token: this.getRefreshToken(),
            })
            .pipe(
                map((result) => {
                    this.setJwtToken(result.token);
                    this.setRefreshToken(result.refresh_token);
                    return true;
                })
            );
    }

    // NOT USED FOR NOW.
    public setAccessTokensForAdminView(jwtToken: string, refreshToken: string): boolean {
        // Cookie.set('access_token', jwtToken, 1, 'new.vk-panel.ru');
        // Cookie.set('refresh_token', refreshToken, 1, 'new.vk-panel.ru');
        return true;
    }

    public logout(): void {
        Cookie.delete('access_token', '/');
        Cookie.delete('refresh_token', '/');
    }

    public get loggedIn(): boolean {
        let tkn = this.getJwtToken();
        if (tkn == null || tkn == undefined) {
            return false;
        }
        return tkn !== '';
    }
}
