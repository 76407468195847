import { Action } from '@ngrx/store';
import { GetTariffFailed } from './user.action';

export const GET_USERS = '[USERS] GET USERS';
export const GET_USERS_SUCCESS = '[USERS] GET USERS SUCCESS';
export const GET_USERS_FAILED = '[USERS] GET USERS FAILED';

export const GET_TARIFS = '[TARIFS] GET TARIFS';
export const GET_TARIFS_SUCCESS = '[TARIFS] GET TARIFS SUCCESS';
export const GET_TARIFS_FAILED = '[TARIFS] GET TARIFS FAILED';

/**
 * Get Users
 */
export class GetUsers implements Action {
    readonly type = GET_USERS;
    constructor() {}
}

/**
 * Get Users Success
 */
export class GetUsersSuccess implements Action {
    readonly type = GET_USERS_SUCCESS;
    constructor(public payload: any) {}
}

/**
 * Get Users Failed
 */
export class GetUsersFailed implements Action {
    readonly type = GET_USERS_FAILED;
    constructor(public payload: string) {}
}

/**
 * Get Tarifs
 */
export class GetTarifs implements Action {
    readonly type = GET_TARIFS;
    constructor() {}
}

/**
 * Get Tarifs Success
 */
export class GetTarifsSuccess implements Action {
    readonly type = GET_TARIFS_SUCCESS;
    constructor(public payload: any) {}
}

/**
 * Get Tarifs Failed
 */
export class GetTarifsFailed implements Action {
    readonly type = GET_TARIFS_FAILED;
    constructor(public payload: string) {}
}

export type AdminActionsAll =
    | GetUsers
    | GetUsersSuccess
    | GetUsersFailed
    | GetTarifs
    | GetTarifsSuccess
    | GetTarifsFailed;
