import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { navigation } from 'app/navigation/navigation';
import { Auth2Service } from '../../../auth.service';
import { select, Store } from '@ngrx/store';
import { State } from '../../../store/reducers';
import { getProfile } from '../../../store/selectors';

@Component({
    selector     : 'toolbar',
    templateUrl  : './toolbar.component.html',
    styleUrls    : ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ToolbarComponent implements OnInit, OnDestroy
{
    user$: Observable<any>;
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    colorTheme: string;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private auth: Auth2Service,
        private _store: Store<State>
    )
    {
        // Set the defaults
        this.navigation = navigation;
        this.user$      = this._store.pipe(select(getProfile));

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.selectTheme(localStorage.getItem('colorTheme') || 'theme-default');

        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) =>
            {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar      = settings.layout.navbar.position === 'right';
                this.hiddenNavbar     = settings.layout.navbar.hidden === true;
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    toggleSidebarOpen(key): void
    {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    logout(): void
    {
        this.auth.logout();
        location.href = '/';
    }

    selectTheme(colorTheme: string): void
    {
        this.colorTheme = colorTheme;
        localStorage.setItem('colorTheme', colorTheme);
        this._fuseConfigService.setConfig({ colorTheme });
    }
}
