export class MarketData {
    id: number;
    vk_group_id: number;
    users_id: number;
    price: number;
    reach_subscriber: number;
    reach_total: number;
    date: string;
    createdAt: string;
    updatedAt: string;

    constructor(data?: any) {
        data = data || {};
        this.id = data.id;
        this.vk_group_id = data.vk_group_id;
        this.users_id = data.users_id;
        this.price = data.price;
        this.reach_subscriber = data.reach_subscriber;
        this.reach_total = data.reach_total;
        this.date = data.date;
        this.createdAt = data.createdAt;
        this.updatedAt = data.updatedAt;
    }
}
