<div class="wording">
    <h1>{{ titleText }}</h1>
    <p>{{ bodyText }}</p>
</div>
<div>
    <mat-dialog-actions class="buttons">
        <button mat-button mat-dialog-close>Отмена</button>
        <button mat-button [mat-dialog-close]="true" (click)="refresh()" class="warn-500 ml-8">
            {{ successButtonText }}
        </button>
    </mat-dialog-actions>
</div>
