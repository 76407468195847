<ng-container *ngIf="!item.hidden">

    <div *ngIf="item.title == 'Информация'">
        <div class="nav-banner">
            <div *ngFor="let ban of banners;let i=index; first as isFirst">
                <div *ngIf="ban.view_block == 2" [style]="isFirst ? '' : 'display: none;'">
                    <a [href]="ban.url" target="_blank">
                        <img [src]="ban.img_url" [alt]="ban.title" [title]="ban.title">
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="group-title" [ngClass]="item.classes">
        <span class="hint-text" [translate]="item.translate">{{item.title}}</span>
    </div>

    <div class="group-items">
        <ng-container *ngFor="let item of item.children">
            <fuse-nav-vertical-group *ngIf="item.type=='group'" [item]="item"></fuse-nav-vertical-group>
            <fuse-nav-vertical-collapsable *ngIf="item.type=='collapsable'" [item]="item">
            </fuse-nav-vertical-collapsable>
            <fuse-nav-vertical-item *ngIf="item.type=='item'" [item]="item"></fuse-nav-vertical-item>
        </ng-container>
    </div>

</ng-container>