import { ITariff, ITariffDiscount } from './../../models/tarif';
import * as UserActions from '../actions/user.action';

export interface UserState
{
    login: boolean;
    profile?: any;
    account?: any;
    tariffDiscount?: ITariffDiscount[];
    tariff: ITariff;
}

export const UserInitialState: UserState = {
    login: false,
    profile: {},
    account: {},
    tariffDiscount: [],
    tariff: {
        id: null,
        title: '',
        type: null,
        price: null,
        limit: null,
        is_default: null,
        createdAt: null,
        updatedAt: null,
        canUsePromoCodes: false
    },
};

export function UserReducer(state = UserInitialState, action: UserActions.UserActionsAll): UserState
{
    switch (action.type)
    {
        case UserActions.GET_PROFILE:
            return {
                ...state,
            };

        case UserActions.UPDATE_PROFILE:

            return {
                ...state,
                profile: {
                    ...state.profile,
                    ...action.payload
                }
            };

        case UserActions.GET_PROFILE_SUCCESS:

            return {
                ...state,
                profile: action.payload
            };

        case UserActions.GET_PROFILE_FAILED:
            return {
                ...state,
            };

        case UserActions.GET_ACCOUNT:
            return {
                ...state,
            };
        case UserActions.GET_ACCOUNT_SUCCESS:

            return {
                ...state,
                account: action.payload
            };

        case UserActions.GET_ACCOUNT_FAILED:
            return {
                ...state,
            };

        case UserActions.GET_TARIFF_DISCOUNT_SUCCESS:
            return {
                ...state,
                tariffDiscount: action.payload,
            };
        case UserActions.GET_TARIFF_SUCCESS:
            return {
                ...state,
                tariff: action.payload,
            }
        case UserActions.LOGIN_SUCCESS:
            return {
                ...state,
                login: true,
            }

                
        default:
            return state;
    }
}
