import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Group } from '../../../models/group';
import { Store } from '@ngrx/store';
import { GetGroups } from 'app/store';
import { environment } from 'environments/environment';
import { HttpService } from 'app/shared/services/http-service';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'app/shared/services/local-storage.service';
import moment from 'moment';

@Injectable()
export class GroupsService {
    onSelectedGroupsChanged: BehaviorSubject<any>;

    selectedGroups: number[] = [];
    groups: { [group: number]: Group } = {};
    urlApi = environment.urlApi;
    date_start: string;
    date_end: string;
    // limit: number;
    // offset: number;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _store: Store<any>,
        private _http: HttpService,
        private _route: ActivatedRoute,
        private storage: LocalStorageService
    ) {
        // Set the defaults
        this.onSelectedGroupsChanged = new BehaviorSubject([]);
    }

    getGroup(id: number): Promise<any> {
        return this._httpClient.get(`${this.urlApi}/group/` + id).toPromise();
    }

    getQueryParams() {
        this._route.queryParams.subscribe((params) => {
            // this.limit = params['limit'] || this.storage.getTablePageSize('statistics-table-page-size') || 25;
            // this.offset = params['offset'] || 0;
            // this.sort = params['sort'] || 'desc';
            // this.created_by = params['created_by'];
            // this.status = params['status'];
            // this.type = params['type'];
            // this.vk_post_id = params['vk_post_id'];
            (this.date_start = params['startDate']), (this.date_end = params['endDate']);
        });
    }

    /**
     * Toggle selected group by id
     *
     * @param id
     */
    toggleSelectedGroup(id): void {
        // First, check if we already have that group as selected...
        if (this.selectedGroups.length > 0) {
            const index = this.selectedGroups.indexOf(id);

            if (index !== -1) {
                this.selectedGroups.splice(index, 1);

                // Trigger the next event
                this.onSelectedGroupsChanged.next(this.selectedGroups);

                // Return
                return;
            }
        }

        // If we don't have it, push as selected
        this.selectedGroups.push(id);

        // Trigger the next event
        this.onSelectedGroupsChanged.next(this.selectedGroups);
    }

    /**
     * Toggle select all
     */
    toggleSelectAll(groups: any[], selectAll?: boolean, deselect?: boolean): void {
        if (selectAll) {
            this.selectGroups(groups);
            return;
        }

        if (deselect) {
            this.deselectGroups();
            return;
        }

        if (this.selectedGroups.length > 0) {
            this.deselectGroups();
        } else {
            this.selectGroups(groups);
        }
    }

    /**
     * Select groups
     */
    selectGroups(groups: any[], filterParameter?, filterValue?): void {
        this.selectedGroups = [];
        // If there is no filter, select all groups
        if (filterParameter === undefined || filterValue === undefined) {
            this.selectedGroups = [];
            groups.map((group) => {
                this.selectedGroups.push(group.vk_id);
            });
        }

        // Trigger the next event
        this.onSelectedGroupsChanged.next(this.selectedGroups);
    }

    filterVkGroups(vkgroups, groups) {
        const filterGroups = vkgroups.filter((vk, i) => {
            const find = groups.find((g) => g.vk_id === vk.id);
            if (!find) {
                return vk;
            }
        });
        const sortGroup = filterGroups.sort((a, b) => (a.members_count < b.members_count ? 1 : -1));
        return sortGroup;
    }

    /**
     * Deselect groups
     */
    deselectGroups(): void {
        this.selectedGroups = [];

        // Trigger the next event
        this.onSelectedGroupsChanged.next(this.selectedGroups);
    }

    setSelectedGroups(groupsIds: number[]) {
        this.selectedGroups = [];
        groupsIds.forEach((element) => {
            this.selectedGroups.push(element);
        });
    }

    activate(): Promise<any> {
        let groupIds = [];
        this.selectedGroups.forEach((element) => {
            groupIds.push(element);
        });
        return this.getRequestsForSelectedStatus(1, groupIds);
    }

    pause(): Promise<any> {
        let groupIds = [];
        this.selectedGroups.forEach((element) => {
            groupIds.push(element);
        });
        return this.getRequestsForSelectedStatus(2, groupIds);
    }

    private getRequestsForSelectedStatus(status: number, groups?: number[]): Promise<any> {
        const body = {
            status: status,
            ids: groups,
        };

        const header = new HttpHeaders({ 'Content-Type': 'application/json' });
        var options = { headers: header };

        return this._httpClient
            .post(`${this.urlApi}/group/set-status-bulk/`, { status: status, ids: groups }, options)
            .toPromise();
    }

    removeGroups(groups: number[]) {
        const body = {
            ids: groups,
        };
        return new Promise<any>((resolve, reject) => {
            this._httpClient.request('delete', `${this.urlApi}/group`, { body }).subscribe(
                (result) => {
                    resolve(this._store.dispatch(new GetGroups()));
                },
                (err) => {
                    // nothing here yet
                }
            );
        });
    }

    updateGroupToken(groupId: number, token: string) {
        return new Promise<any>((resolve, reject) => {
            this._http.putRequest(`/group/${groupId}`, { token }).subscribe(resolve, reject);
        });
    }

    saveGroupStatus(groupId: number, status: number): any {
        status = status ? 1 : 2;
        return new Promise<any>((resolve, reject) => {
            this._http
                .postRequest(`/group/set-status/${groupId}`, { status })
                .subscribe((result) => {
                    resolve(this._store.dispatch(new GetGroups()));
                }, reject);
        });
    }

    getGroupsPatchValue(group_ids): Promise<any> {
        const { date_start, date_end } = this.storage.getGroupRemovedFilter();
    
        let tmp_date_start = date_start;
        let tmp_date_end = date_end;
    
        if (
            (date_start == 0 && date_end == 0) ||
            date_start == undefined ||
            date_end == undefined
        ) {
            tmp_date_start = moment().subtract(4, 'days').startOf('day').format();
            tmp_date_end = moment().subtract(1, 'days').endOf('day').format();
        }
    
        return this._httpClient
            .post(`${this.urlApi}/wall/statistics`, {
                group_ids,
                date_start: tmp_date_start,
                date_end: tmp_date_end,
            })
            .toPromise();
    }
    
    

    getAccountStatus(tariffId: number): Promise<any> {
        return this._httpClient.get(`${this.urlApi}/tariff/${tariffId}`).toPromise();
    }

    /* Section related to the Group Update Job */

    checkIfParseJob(): Promise<any> {
        return this._httpClient.get(`${this.urlApi}/group-has-parse-tasks/`).toPromise();
    }

    updageJobCurrentStatus(groupId: number): Promise<any> {
        return this._httpClient
            .get(`${this.urlApi}/group/get_market_data/${groupId}?current=true`)
            .toPromise();
    }

    updateJobStatus(groupId: number): Promise<any> {
        return this._httpClient.get(`${this.urlApi}/group/parse-status/${groupId}`).toPromise();
    }

    updateJobStop(groupId: number): Promise<any> {
        return this._httpClient.get(`${this.urlApi}/group/cancel-parse/${groupId}`).toPromise();
    }

    /* Section with methods related to the Remove Job */

    checkIfRemoveJob(): Promise<any> {
        return this._httpClient.get(`${this.urlApi}/wall/has-remove-tasks/`).toPromise();
    }

    deleteMassGroupAction(groupId: number, startDate: string, endDate: string): Promise<any> {
        const body = {
            max_date: endDate,
            min_date: startDate,
        };
        return this._httpClient
            .request('delete', `${this.urlApi}/wall/${groupId}`, { body })
            .toPromise();
    }

    deleteMassGroupActionStatus(groupId: number): Promise<any> {
        return this._httpClient
            .get(`${this.urlApi}/wall/${groupId}/get-remove-progress`)
            .toPromise();
    }

    deleteMassGroupActionStop(groupId: number): Promise<any> {
        return this._httpClient.get(`${this.urlApi}/wall/${groupId}/cancel-remove/`).toPromise();
    }
}
