import { createSelector } from '@ngrx/store';
import { GroupsState } from '../reducers/group.reducer';
import { getGroupsState } from '../reducers';

export const getGroups = createSelector(getGroupsState, (state: GroupsState) => state.groups);

export const getGroupsLoaded = createSelector(
    getGroupsState,
    (state: GroupsState) => state.groupsLoaded
);

export const getGroupsArr = createSelector(getGroups, (entities) =>
    Object.keys(entities).map((id) => entities[id])
);

export const getCategories = createSelector(
    getGroupsState,
    (state: GroupsState) => state.categories
);

export const getCategoriesArr = createSelector(getCategories, (entities) =>
    Object.keys(entities).map((id) => entities[id])
);

export const getCategoriesLoaded = createSelector(
    getGroupsState,
    (state: GroupsState) => state.categoriesLoaded
);

export const getVkGroups = createSelector(getGroupsState, (state: GroupsState) => {
    return {
        vkGroups: state.vkGroups,
        groups: Object.keys(state.groups).map((id) => state.groups[id]),
    };
});

export const GroupsLoading = createSelector(getGroupsState, (state: GroupsState) => state.loading);

export const getGroupsVkToken = createSelector(
    getGroupsState,
    (state: GroupsState) => state.badVkToken
);
