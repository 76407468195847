import { RouterEffects } from './router.effect';
import { UserEffects } from './user.effects';
import { GroupEffects } from './group.effects';
import { NatificationEffects } from './natification.effects';
import { AdminActionsEffects } from './admin.effects';

export const effects: any[] = [RouterEffects, UserEffects, GroupEffects, NatificationEffects, AdminActionsEffects];

export * from './router.effect';
export * from './user.effects';
export * from './group.effects';
export * from './natification.effects';
export * from './admin.effects';

