<div class="nav" [ngClass]="{'horizontal':layout === 'horizontal', 'vertical':layout === 'vertical'}">

    <!-- Vertical Navigation Layout -->
    <ng-container *ngIf="layout === 'vertical'">
        <div *ngIf="tariffType == 1 && serviceExpired" class="payReminder">
            <span>Пополните счет на <b>{{amount}}</b> рублей чтобы продлить тариф</span>
            <br><br>
            <!--  class="payButton"  -->
            <button mat-raised-button
                class="payButton amber-bg fuse-black-fg font-weight-400 ng-tns-c318-13 mat-raised-button mat-button-base"
                [routerLink]="'/apps/finance'">
                Пополнить
            </button>
        </div>
        <div *ngIf="tariffType == 1 && !serviceExpired && !isMobile" class="payReminder">
            <span>Тариф действует до:</span><br>
            <span>{{serviceExpirationDate}}</span>
            <br><br>
        </div>
        <div *ngIf="is_trial" class="payReminder">
            <span>Триал-тариф действует до:</span><br>
            <span>{{serviceExpirationDate}}</span>
            <br><br>
            <button mat-raised-button
                class="payButton amber-bg fuse-black-fg font-weight-400 ng-tns-c318-13 mat-raised-button mat-button-base"
                (click)="changeTariff()">
                Сменить тариф
            </button>
        </div>

        <ng-container *ngFor="let item of navigation">
            <fuse-nav-vertical-group *ngIf="item.type=='group'" [item]="item" [banners]="bannersForTwo">
            </fuse-nav-vertical-group>
            <fuse-nav-vertical-collapsable *ngIf="item.type=='collapsable'" [item]="item">
            </fuse-nav-vertical-collapsable>
            <fuse-nav-vertical-item *ngIf="item.type=='item'" [item]="item"></fuse-nav-vertical-item>
        </ng-container>

        <div class="nav-banner nav-banner-bott">
            <div *ngFor="let ban of banners;let i=index; first as isFirst">
                <div *ngIf="ban.view_block == 3" [style]="isFirst ? '' : 'display: none;'">
                    <a [href]="ban.url" target="_blank">
                        <img [src]="ban.img_url" [alt]="ban.title" [title]="ban.title">
                    </a>
                </div>
            </div>
        </div>
    </ng-container>
    <!-- / Vertical Navigation Layout -->

    <!-- Horizontal Navigation Layout -->
    <ng-container *ngIf="layout === 'horizontal'">
        <ng-container *ngFor="let item of navigation">
            <fuse-nav-horizontal-collapsable *ngIf="item.type=='group'" [item]="item"></fuse-nav-horizontal-collapsable>
            <fuse-nav-horizontal-collapsable *ngIf="item.type=='collapsable'" [item]="item">
            </fuse-nav-horizontal-collapsable>
            <fuse-nav-horizontal-item *ngIf="item.type=='item'" [item]="item"></fuse-nav-horizontal-item>
        </ng-container>
    </ng-container>
    <!-- / Horizontal Navigation Layout -->
</div>