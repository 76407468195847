import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { merge, Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';

import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { select, Store } from '@ngrx/store';
import { GetAccount } from 'app/store';
import { getAccount, getTariff } from 'app/store/selectors';
import { State } from 'app/store/reducers';
import { GroupsService } from 'app/main/apps/groups/groups.service';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { TariffService } from 'app/shared/services/tariff.service';
import { Auth2Service } from 'app/auth.service';
import { Banner, BannersService } from 'app/shared/services/banners.service';
import { ITariff } from 'app/models/tarif';

@Component({
    selector: 'fuse-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FuseNavigationComponent implements OnInit {
    @Input()
    layout = 'vertical';
    @Input()
    navigation: any;
    @Input()
    isMobile: boolean;
    amount: number = 0;
    is_local_time: boolean;
    serviceExpirationDate: string;
    serviceExpired: boolean = false;
    tariff: ITariff;
    tariffType: number = 0;
    is_trial: boolean = false;

    // Private
    private _unsubscribeAll: Subject<any>;

    public banners: Banner[] = [];
    public bannersForTwo: Banner[] = [];
    public account: any;
    public tarif: any;

    private emitter = new EventEmitter<boolean>();
    public accountIsReady: boolean = false;
    public tarifIsReady: boolean = false;

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _auth2Service: Auth2Service,
        private store: Store<State>,
        private groupsService: GroupsService,
        private datePipe: DatePipe,
        private tariffService: TariffService,
        private readonly _bannerService: BannersService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Load the navigation either from the input or from the service
        this.navigation = this.navigation || this._fuseNavigationService.getCurrentNavigation();
        // Subscribe to the current navigation changes
        this._fuseNavigationService.onNavigationChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                // Load the navigation
                this.navigation = this._fuseNavigationService.getCurrentNavigation();

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        )
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        if (this._auth2Service.loggedIn) {
            this.store.dispatch(new GetAccount());

            this.store.pipe(select(getTariff)).subscribe((tariff) => {
                this.tariff = tariff;

                if (this.tariff != undefined && this.tariff != null && this.tariff.id != null) {
                    this.tarifIsReady = true;
                    this.emitter.emit(true);
                }
            });
        }

        this.store.pipe(select(getAccount)).subscribe((account) => {
            if (account != null && account && account.date_service_end != undefined) {
                this.account = account;

                this.is_trial = account.tariff_id == 10001 ? true : false;
                this.is_local_time = account.is_local_time;
                const currentDate = new Date().getTime();
                const paymentDate = new Date(account.date_service_end).getTime();
                this.serviceExpirationDate = this.datePipe.transform(
                    new Date(account.date_service_end),
                    'dd.MM.yyyy HH:mm'
                );

                this.serviceExpirationDate = this.transform(this.serviceExpirationDate, 'Local');

                let now = moment.utc(currentDate).utcOffset(0).format('DD.MM.YYYY HH:mm');
                let src = moment.utc(paymentDate).utcOffset(0).format('DD.MM.YYYY HH:mm');
                let currentDateT = this.transform(now, 'Local');

                let paymentDateT = this.transform(src, 'Local');
                // assuming that all inputs are coming in UTC0
                let date1 = currentDateT.split(' ')[0];
                let time1 = currentDateT.split(' ')[1];
                let dateInDetails = date1.split('.');
                let timeInDetails = time1.split(':');

                const mDateCurrent = new Date(
                    Number(dateInDetails[2]),
                    Number(dateInDetails[1]) - 1,
                    Number(dateInDetails[0]),
                    Number(timeInDetails[0]),
                    Number(timeInDetails[1]),
                    0
                );

                // assuming that all inputs are coming in UTC0
                let date2 = paymentDateT.split(' ')[0];
                let time2 = paymentDateT.split(' ')[1];

                dateInDetails = date2.split('.');
                timeInDetails = time2.split(':');

                const mDatePayment = new Date(
                    Number(dateInDetails[2]),
                    Number(dateInDetails[1]) - 1,
                    Number(dateInDetails[0]),
                    Number(timeInDetails[0]),
                    Number(timeInDetails[1]),
                    0
                );

                if (mDatePayment > mDateCurrent) {
                    this.serviceExpired = false;
                } else {
                    this.serviceExpired = true;
                }

                if (account.tariff_id != undefined && account.tariff_id != null) {
                    this.groupsService.getAccountStatus(account?.tariff_id).then(async (res) => {
                        this.tariffType = res?.type ? res.type : 0;
                        this.amount = res.price;

                        this.accountIsReady = true;
                        this.emitter.emit(true);
                    });
                }
            }

            this._changeDetectorRef.markForCheck();
            this._changeDetectorRef.detectChanges();
        });

        this.emitter.subscribe((event) => {
            if (this.accountIsReady || this.tarifIsReady) {
                // request banners
                this._bannerService.requestAdsInfo().then((resB: any) => {
                    if (resB && resB.result == true) {
                        this.banners = [];
                        this.bannersForTwo = [];
                        resB.banners.forEach((element) => {
                            let tmpB = new Banner(element);
                            this.banners.push(tmpB);
                            this.bannersForTwo.push(tmpB);
                        });
                    }

                    // fix if no data
                    if (this.tariff.type == null) {
                        this.tariff.type = this.tariffType;
                    }

                    this.banners = this.banners.filter((b) => {
                        try {
                            const isEvaluated = eval(b.view_condition);
                            return isEvaluated && b.view_block == 3;
                        } catch (error) {
                            return false;
                        }
                    });

                    this.banners = this.banners.sort((a, b) => a.view_order - b.view_order);

                    this.bannersForTwo = this.bannersForTwo.filter((b) => {
                        try {
                            const isEvaluated = eval(b.view_condition);
                            return isEvaluated && b.view_block == 2;
                        } catch (error) {
                            return false;
                        }
                    });
                    this.bannersForTwo = this.bannersForTwo.sort(
                        (a, b) => a.view_order - b.view_order
                    );

                    this._changeDetectorRef.markForCheck();
                    this._changeDetectorRef.detectChanges();
                });
            }
        });
    }

    // need to be here to support all conditions
    hasGroupWithStatus(status: number) {
        return false;
    }

    /**
     * Evaluate condition from the backend
     * @param condition
     */
    evaluate(condition: string) {
        // not secured
        // WAS DISCUSSED BEFORE IMPLEMENTING
        // !!!
        if (
            condition.length > 255 ||
            condition.includes('http') ||
            condition.includes('timer') ||
            condition.includes('{') ||
            condition.includes('}') ||
            condition.includes('console') ||
            condition.includes('print') ||
            condition.includes('alert') ||
            condition.includes('->') ||
            condition.includes('script')
        ) {
            console.log('Security break.');
            return false;
        }

        if (condition.length == 0) {
            return true;
        }

        if (this.account) return eval(condition);
        else return false;
    }

    hasPlugin() {
        let tmp = this._bannerService.checkIfPluginIsPresent();
        return tmp;
    }

    checkDateBefore(date: string) {
        let inputDate = new Date(date);
        return new Date() < inputDate;
    }

    transform(value: string, timeZone: string = 'MSK'): string | null {
        if (value == null || timeZone == null || value == undefined || value == '') {
            return '';
        }

        // assuming that all inputs are coming in UTC0
        let date = value.split(' ')[0];
        let time = value.split(' ')[1];

        let dateInDetails = date.split('.');
        let timeInDetails = time.split(':');

        const mDate = new Date(
            Number(dateInDetails[2]),
            Number(dateInDetails[1]) - 1,
            Number(dateInDetails[0]),
            Number(timeInDetails[0]),
            Number(timeInDetails[1]),
            0
        );

        return new DatePipe('ru').transform(
            this.convertUTCDateToLocalDate(mDate),
            'dd.MM.yyyy HH:mm',
            timeZone == 'MSK' ? 'GMT+3' : ''
        );
    }

    convertUTCDateToLocalDate(date) {
        var newDate = new Date(date);
        var offset = date.getTimezoneOffset() / 60;
        var hours = date.getHours();
        var day = date.getDate() - 1;

        newDate.setHours(hours - offset);
        if (hours < offset) {
            if (day <= 0) {
                day = 1;
                var month = newDate.getMonth() - 1;
                if (month < 0) {
                    month = 0;
                    newDate.setFullYear(newDate.getFullYear() - 1);
                }
                newDate.setMonth(month);
            }
            newDate.setDate(day);
        }
        return newDate;
    }

    changeTariff() {
        this.tariffService.change().then((res) => {
            window.location.reload();
        });
    }
}
