import { FuseNavigation } from '@fuse/types';
import { Store } from '@ngrx/store';

export const navigation: FuseNavigation[] = [
    {
        id       : 'applications',
        title    : 'Главное меню',
        translate: null,
        type     : 'group',
        icon     : 'apps',
        children : [
            {
                id       : 'groups',
                title    : 'Управление группами',
                translate: null,
                type     : 'item',
                icon     : 'group',
                url      : '/apps/groups',
            },
            {
                id       : 'profile',
                title    : 'Профиль',
                translate: null,
                type     : 'collapsable',
                icon     : 'person',
                children: [
                    {
                        id: 'notification',
                        title: 'Настройки',
                        translate: null,
                        type: 'item',
                        icon: 'settings',
                        url: '/apps/profile/settings'
                    },
                    {
                            id: 'security',
                            title: 'Безопасность',
                            translate: null,
                            type: 'item',
                            icon: 'security',
                            url: '/apps/profile/security'
                    },
                    {
                        id: 'notifications',
                        title: 'Уведомления',
                        translate: null,
                        type: 'item',
                        icon: 'notifications',
                        url: '/apps/profile/notifications'
                    }
                ]
            },
            {
                id       : 'finance',
                title    : 'Финансы',
                translate: null,
                type     : 'item',
                icon     : 'attach_money',
                url      : '/apps/finance',
            },
            {
                id       : 'stats-by-editors',
                title    : 'Статистика по редакторам',
                translate: null,
                type     : 'item',
                icon     : 'assessment',
                url      : '/apps/stats-by-editors',
            },
            {
                id       : 'invite-friend',
                title    : 'Пригласи друга',
                translate: null,
                type     : 'item',
                icon     : 'group_add',
                url      : '/apps/invite-friend',
            },
        ],
    },
    {
        id       : 'info',
        title    : 'Информация',
        translate: null,
        type     : 'group',
        icon     : 'info',
        children : [
            {
                id       : 'cases',
                title    : 'Настройки и кейсы',
                translate: null,
                type     : 'item',
                url      : '/apps/cases',
                icon     : 'business_center',
            },
            {
                id          : 'faq',
                title       : 'Отзывы',
                translate   : null,
                type        : 'item',
                icon        : 'help',
                url         : 'https://vk.com/wall-164537430_430',
                externalUrl : true,
                openInNewTab: true
            },
        ]
    },
];
