export interface ITariff {
    id: number;
    title: string;
    type: number;
    price: number;
    limit: number;
    is_default: number;
    createdAt: Date;
    updatedAt: Date;
    canUsePromoCodes: boolean;
}

export interface ITariffDiscount {
    tariff_id: number;
    period: number;
    discount: number;
    createdAt: Date;
    updatedAt: Date;
}

/**
 * Implementation for Tarif instance
 */
export class Tarif implements ITariff {
    id: number;
    title: string;
    type: number;
    price: number;
    limit: number;
    is_default: number;
    createdAt: Date;
    updatedAt: Date;
    canUsePromoCodes: boolean;

    constructor(data?: any) {
        data = data || {};
        this.id = data.id;
        this.title = data.title;
        this.type = data.type;
        this.price = data.price;
        this.limit = data.limit;
        this.is_default = data.is_default;
        this.createdAt = data.createdAt;
        this.updatedAt = data.updatedAt;
        this.canUsePromoCodes = data.canUsePromoCodes;
    }
}
