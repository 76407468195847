import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse,
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Auth2Service } from '../auth.service';
import { RefreshTokenModalComponent } from 'app/shared/components/refresh-token-modal/refresh-token-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { FuseProgressBarComponent } from '@fuse/components/progress-bar/progress-bar.component';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalStorageService } from 'app/shared/services/local-storage.service';
import { environment } from 'environments/environment.prod';
import { debug } from 'console';

@Injectable()
export class APIInterceptor implements HttpInterceptor {
    private isStartRefreshing = false;
    private tokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private modalRef = null;

    // flag to signalize that we are already in Error state
    private inError = false;

    constructor(
        private router: Router,
        private auth2Service: Auth2Service,
        private modal: MatDialog,
        private _matSnackBar: MatSnackBar,
        private _fuseProgressBarService: FuseProgressBarService,
        private readonly _storage: LocalStorageService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!!this.auth2Service.getJwtToken()) {
            request = this.addToken(request, this.auth2Service.getJwtToken());
        }

        return next.handle(request).pipe(
            catchError((error) => {
                if (this.inError) {
                    return;
                }

                this.printTrace(error);

                if (
                    error instanceof HttpErrorResponse &&
                    error.statusText === 'Unknown Error' &&
                    error.status == 502
                ) {
                    this.printTrace('1');
                    this.auth2Service.clearTokens();
                    this.router.navigate(['pages/auth/login']);
                    this._matSnackBar.open('Что-то пошло не так. Мы уже чиним ...', 'OK', {
                        verticalPosition: 'top',
                        duration: 2000,
                    });
                }

                // error handling
                let errMessage = error.error.message ? error.error.message : '';
                let errCode = error.error.code;
                let errUrl = error.url;

                this.printTrace(errMessage);
                this.printTrace(errCode);
                this.printTrace(errUrl);

                if (errCode == 429) {
                    this.printTrace('429');
                    return;
                }

                // prevent any action on notification requests
                // errUrl == 'https://api-new.vk-panel.ru/api/notifications'
                if (
                    errMessage == 'Unauthorized' &&
                    errUrl == environment.urlApi + '/notifications'
                ) {
                    return;
                }

                if (
                    errMessage == '' &&
                    error.error.error == undefined &&
                    errCode == undefined &&
                    !error.url.includes('chrome-extension://') &&
                    error.url.includes('group/undefined')
                ) {
                    this.printTrace('2');
                    return;
                }

                // this exception
                if (
                    errMessage == '' &&
                    error.error.error == undefined &&
                    errCode == undefined &&
                    !error.url.includes('chrome-extension://')
                ) {
                    this.printTrace('3');
                    // this.router.navigate(['pages/auth/login']);
                    this._matSnackBar.open(
                        'На VK Panel идут плановые технические работы. В данный момент мы что-то улучшаем. Ожидайте, скоро всё заработает :)',
                        'OK',
                        {
                            verticalPosition: 'top',
                            duration: 2000,
                        }
                    );

                    // this.auth2Service.clearTokens();
                    this._fuseProgressBarService.hide();
                    return;
                }

                if (
                    (errMessage == 'Нет привязанного аккаунта вк!' ||
                        errMessage == 'invalid users api key') &&
                    errCode === 0
                ) {
                    this.printTrace('4');
                    // throw error,
                    // group.effect will take and open window
                    this.showUpdateTokenModal();
                    return;
                }

                if (error instanceof HttpErrorResponse && error.status === 401) {
                    if (!!this.auth2Service.getRefreshToken()) {
                        if (!this.isStartRefreshing) {
                            this.isStartRefreshing = true;
                            this.tokenSubject.next(null);

                            this.printTrace('5');

                            return this.auth2Service.refreshAccessToken().pipe(
                                take(1),
                                tap(() => {
                                    this.isStartRefreshing = false;
                                    this.tokenSubject.next(this.auth2Service.getJwtToken());
                                }),
                                switchMap(() => {
                                    return next.handle(
                                        this.addToken(request, this.auth2Service.getJwtToken())
                                    );
                                })
                            );
                        } else {
                            this.printTrace('6');
                            return this.tokenSubject.pipe(
                                filter((token) => token != null),
                                take(1),
                                switchMap((jwt) => {
                                    return next.handle(this.addToken(request, jwt));
                                })
                            );
                        }
                    } else {
                        this.printTrace('7');
                        // if refresh token is not available -> login
                        // but access is available
                        if (
                            (this.auth2Service.getRefreshToken() == null ||
                                this.auth2Service.getRefreshToken() == undefined) &&
                            this.auth2Service.getJwtToken() != null
                        ) {
                            this.printTrace('8');
                            this.isStartRefreshing = false;
                            this.auth2Service.clearTokens();
                            this.router.navigate(['pages/auth/login']);
                        }
                        // if both are available - try to refresh
                        else {
                            this.printTrace('9');
                            // i'm here
                            this.onRefresh();
                            return throwError(error);
                        }
                    }
                } else {
                    this.printTrace('10');
                    // if refresh cannot be processed
                    if (
                        errUrl == environment.urlApi + '/refresh' &&
                        errMessage == 'invalid session'
                    ) {
                        this.printTrace('11');
                        // just in case - we need to clean vars
                        this.isStartRefreshing = false;
                        this.auth2Service.clearTokens();
                        this.router.navigate(['pages/auth/login']);
                    }

                    // if wrong page
                    if (errMessage == 'Группа вам не принадлежит или отключена!') {
                        this.router.navigate(['/apps/groups']);
                    } else {
                        this.printTrace('12');

                        if (error.url == environment.urlApi + '/vk-group') {
                            this.printTrace('13');
                            return throwError(error);
                        } else if (error.url.includes('chrome-extension://')) {
                            this.printTrace('14');
                            return throwError(error);
                        } else if (error.error.error == 'Запись не найдена!') {
                            this.printTrace('15');
                            return throwError(error);
                        } else if (error.status != 200 && error.statusText != 'OK') {
                            this.printTrace('16');
                            this.router.navigate(['pages/auth/login']);
                            return throwError(error);
                        }
                    }

                    this.printTrace('20');
                    return throwError(error);
                }
            })
        );
    }

    private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    /**
     * Open modal with update VK-token request
     */
    private showUpdateTokenModal() {
        if (this.modalRef == null) {
            this.inError = true;
            this.modalRef = this.modal.open(RefreshTokenModalComponent, {
                disableClose: true,
            });

            (<RefreshTokenModalComponent>this.modalRef.componentInstance).titleText =
                'VK-токен недоступен';
            (<RefreshTokenModalComponent>this.modalRef.componentInstance).bodyText =
                'Пройдите процедуру логина для обновления токена.';
            (<RefreshTokenModalComponent>this.modalRef.componentInstance).successButtonText =
                'Обновить';
            this._fuseProgressBarService.hide();
        }
    }

    private onRefresh() {
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };

        let currentUrl = this.router.url + '?';

        this.router.navigateByUrl(currentUrl).then(() => {
            this.router.navigated = false;
            this.router.navigate([this.router.url]);
        });
    }

    private printTrace(place: string) {
        //console.log(place);
    }

    // private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    // {
    //     if (!this.isStartRefreshing)
    //     {
    //         this.isStartRefreshing = true;
    //         this.tokenSubject.next(null);
    //
    //         this.isStartRefreshing = false;
    //         this.tokenSubject.next(this.auth2Service.getRefreshToken());
    //         return next.handle(this.addToken(request, this.getRefreshToken()));
    //     }
    //     else
    //     {
    //         return this.tokenSubject.pipe(
    //             filter(token => token != null),
    //             take(1),
    //             switchMap(jwt =>
    //             {
    //                 return next.handle(this.addToken(request, jwt));
    //             }));
    //     }
    // }
}
