import { createSelector } from '@ngrx/store';
import { UserState } from '../reducers/user.reducer';
import { getUserState } from '../reducers';

export const getProfile = createSelector(
    getUserState,
    (state: UserState) => {
        return {
            ...state.profile,
            ...state.account
        };
    }
);



export const getAccount = createSelector(
    getUserState,
    (state: UserState) =>
    {
        return state.account;
    }
);

export const getTariffDiscount = createSelector(
    getUserState,
    (state: UserState) => state.tariffDiscount,
);

export const getTariff = createSelector(
    getUserState,
    (state: UserState) => state.tariff
);

export const getProfileLoaded = createSelector(
    getProfile,
    (profile: any) =>
    {
        return typeof profile === 'object' && Object.keys(profile).length > 0;
    }
);

export const getAccountLoaded = createSelector(
    getAccount,
    (account: any) =>
    {
        return typeof account === 'object' && Object.keys(account).length > 0;
    }
);

export const getLogin = createSelector(
    getUserState,
    (state: UserState) => state.login
);