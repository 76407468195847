/**
 * User entity definition
 */
export class User {
    users_id: number;
    first_name: string;
    last_name: string;
    email: string;
    photo: string;
    balance: string;
    is_local_time: boolean;
    tariff_id: string;
    autopay: string;
    createdAt: string;
    coveragePeriod: number;

    constructor(data?: any) {
        data = data || {};
        this.users_id = data.users_id;
        this.first_name = data.first_name;
        this.last_name = data.last_name;
        this.email = data.email;
        this.photo = data.photo;
        this.balance = data.balance;
        this.tariff_id = data.tariff_id;
        this.autopay = data.autopay;
        this.createdAt = data.createdAt;
        this.coveragePeriod = data.coveragePeriod != undefined ? data.coveragePeriod : 7;
    }

    // Just in case if needed
    // constructor(users_id?: number, first_name?: string, last_name?: string,
    //     email?: string, photo?: string, balance?: string, tariff_id?: string, autopay?: string) {
    //     this.users_id = users_id;
    //     this.first_name = first_name;
    //     this.last_name = last_name;
    //     this.email = email;
    //     this.photo = photo;
    //     this.balance = balance;
    //     this.tariff_id = tariff_id;
    //     this.autopay = autopay;
    // }
}
