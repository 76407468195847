import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, limit = 25, firstChart = false, ellipsis = '...') {
    if (value.length > limit && firstChart) {
      return value.substr(0, 1)
    }
    return value.length > limit ? value.substr(0, limit) + ellipsis : value;
  }
}