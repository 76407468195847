import { Injectable } from '@angular/core';
import { MatSort } from '@angular/material/sort';

@Injectable({
    providedIn: 'root',
})
export class LocalStorageService {
    constructor() { }

    addOAuthFlag(value: string) {
        localStorage.setItem('oauth-flag', value);
    }

    getOAuthFlag() {
        return localStorage.getItem('oauth-flag');
    }

    getColorTheme() {
        return localStorage.getItem('colorTheme');
    }

    addFilterPageRecommend(displayed: string) {
        localStorage.setItem('filters-page-recommendation', displayed);
    }

    getFilterPageRecommend() {
        return localStorage.getItem('filters-page-recommendation');
    }

    addGroupsGroupping(enabled: string) {
        localStorage.setItem('groups-groupping', enabled);
    }

    getGroupsGroupping() {
        return localStorage.getItem('groups-groupping');
    }

    addAccountExtras(userId: any, email: any) {
        localStorage.setItem('account-extras', userId + '|' + email);
    }

    getAccountExtras() {
        return localStorage.getItem('account-extras');
    }

    getTemplateGuide() {
        return localStorage.getItem('template-guide')
    }

    addTemplateGuide(value) {
        localStorage.setItem('template-guide', JSON.stringify(value));
    }

    addGroupSetBody(body) {
        localStorage.setItem('add-group-body', JSON.stringify(body));
    }

    addGroupGetBody() {
        return JSON.parse(localStorage.getItem('add-group-body'));
    }
    addGroupClearBody() {
        localStorage.removeItem('add-group-body');
    }

    setTablePageSize(table, size) {
        localStorage.setItem(table, size);
    }

    getTablePageSize(table): number {
        return +localStorage.getItem(table);
    }

    setTimePreference(key: string, value: string): void {
        localStorage.setItem(key, value);
    }

    getTimePreference(key: string): string {
        return localStorage.getItem(key);
    }

    setSort(value: MatSort): void {
        localStorage.setItem('sort-group-table', value.active + ':' + value.direction);
    }

    getSort(): string {
        return localStorage.getItem('sort-group-table');
    }

    setPrompt(key: string, value: any): void {
        localStorage.setItem(key, value);
    }

    getPrompt(key: string): string {
        return localStorage.getItem(key);
    }

    setGroupRemovedFilter(value: any): void {
        localStorage.setItem('group-removed-filter', JSON.stringify(value));
    }

    setGroupCoveredFilter(value: any): void {
        localStorage.setItem('group-covered-filter', JSON.stringify(value));
    }

    getGroupCoveredFilter(): any {
        const filter = JSON.parse(localStorage.getItem('group-covered-filter')) || {};
        return {
            activeDate: filter.activeDate || null,
            activeField: filter.activeField || null,
            date_start: filter.date_start || 0,
            date_end: filter.date_end || 0,
            customDate: filter.customDate || 0,
        };
    }

    getGroupRemovedFilter(): any {
        const filter = JSON.parse(localStorage.getItem('group-removed-filter')) || {};
        return {
            activeDate: filter.activeDate || null,
            activeField: filter.activeField || 'simpleDay',
            date_start: filter.date_start || 0,
            date_end: filter.date_end || 0,
            customDate: filter.customDate || 2,
        };
    }

    setShortcutItems(items): void {
        localStorage.setItem('FUSE2.shortcuts', JSON.stringify(items));
    }

    getShortcutItems(): any {
        return localStorage.getItem('FUSE2.shortcuts');
    }
}
