export const environment = {
    production: true,
    hmr: false,
    urlApi: 'https://api-new.vk-panel.ru/api',
    //urlApi: 'https://devapi.vk-panel.ru/api',
    urlVkApi: 'https://oauth.vk.com/authorize',
    backendUrl: 'https://api-new.vk-panel.ru',
    timeoutSnackBar: 5000,
    amountSymbolsInPassword: 6,
    clientIdVk: 7704017,
    versionVkApi: 5.122,

    //originUrl: 'https://devfront.vk-panel.ru',
    originUrl: 'https://new.vk-panel.ru',
    //originUrl: 'http://localhost:4200',
};