export class ContentProtection {
    id: number;
    vk_group_id: number;
    users_id: number;
    protect_count: number;
    compare_by: string;
    enabled: boolean;
    createdAt: string;
    updatedAt: string;

    constructor(data?: any) {
        data = data || {};
        this.id = data.id;
        this.vk_group_id = data.vk_group_id;
        this.users_id = data.users_id;
        this.protect_count = data.protect_count;
        this.compare_by = data.compare_by;
        this.enabled = data.enabled;
        this.createdAt = data.createdAt;
        this.updatedAt = data.updatedAt;
    }
}
