/* tslint:disable */
import { Group } from './group';

export class MassFilter {
    mass_filter_id: any;
    users_id: any;
    description_for_template: any;
    filter: string;
    time: any;
    type: any;
    field_path: any;
    is_not_remove: any;
    createdAt: any;
    updatedAt: any;
    group_to_mass_filter: {
        groups_id: any;
        mass_filter_id: any;
        createdAt: any;
        updatedAt: null;
    };
    groups: any[] = [];

    fullTime: { hours: number; minutes: number };
    immediat_remove: any;
    description: any;
    caseSensitive: boolean;  // новое свойство

    constructor(data?: any) {
        data = data || {};
        this.mass_filter_id = data.mass_filter_id;
        this.description_for_template = data.description_for_template;
        this.users_id = data.users_id;
        this.filter = data.filter;
        this.time = data.time;
        this.type = data.type;
        this.is_not_remove = data.is_not_remove;
        this.createdAt = data.createdAt;
        this.updatedAt = data.updatedAt;
        this.group_to_mass_filter = data.group_to_mass_filter;
        this.immediat_remove = data.immediat_remove;
        this.field_path = data.field_path;
        this.description = data.description;
        this.caseSensitive = data.caseSensitive;  // инициализация нового свойства

        this.groups = data.groups;
        this.fullTime = this.splitTime(this.time);
    }

    splitTime(value: any): { hours: number; minutes: number } {
        let hours = 0;
        let minutes = 0;

        if (!isNaN(parseFloat(value))) {
            hours = Math.floor(parseFloat(value) / 60);
            minutes = value - hours * 60;
        }

        return {
            hours: hours < 0 ? 0 : hours,
            minutes: minutes < 0 ? 0 : minutes,
        };
    }
}
