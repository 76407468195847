/* tslint:disable:variable-name */
export class Category
{
    groups_category_id: number;
    title: string;
    users_id: number;
    createdAt: string;
    updatedAt: string;

    constructor(data?: any)
    {
        data = data || {};
        this.groups_category_id = data.groups_category_id;
        this.title              = data.title || null;
        this.users_id           = data.users_id;
        this.createdAt          = data.createdAt;
        this.updatedAt          = data.updatedAt;
    }
}
