import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import * as UserActions from '../actions/user.action';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable()
export class UserEffects {
    private urlApi = environment.urlApi;

    /**
     * Constructor
     *
     * @param actions
     * @param http
     * @param store$
     */
    constructor(private actions: Actions, private http: HttpClient, private store$: Store<any>) {}

    /**
     * Get Profile from Server
     */
    @Effect()
    getProfile: Observable<UserActions.UserActionsAll> = this.actions.pipe(
        ofType<UserActions.GetProfile>(UserActions.GET_PROFILE),
        switchMap((action) => {
            return this.http.get(`${this.urlApi}/account.profile`).pipe(
                map((profile: any) => {
                    return new UserActions.GetProfileSuccess(profile);
                }),
                catchError((err) => of(new UserActions.GetProfileFailed(err)))
            );
        })
    );

    /**
     * Get Account from Server
     */
    @Effect()
    getAccount: Observable<UserActions.UserActionsAll> = this.actions.pipe(
        ofType<UserActions.GetAccount>(UserActions.GET_ACCOUNT),
        switchMap((action) => {
            return this.http.get(`${this.urlApi}/account`).pipe(
                map((profile: any) => {
                    return new UserActions.GetAccountSuccess(profile);
                }),
                catchError((err) => of(new UserActions.GetAccountFailed(err)))
            );
        })
    );

    @Effect()
    getTarff$: Observable<UserActions.UserActionsAll> = this.actions.pipe(
        ofType<UserActions.GetTariff>(UserActions.GET_TARIFF),
        switchMap((action) => {
            return this.http.get(`${this.urlApi}/tariff/${action.payload}`).pipe(
                map((tariff: any) => {
                    return new UserActions.GetTariffSuccess(tariff);
                }),
                catchError((err) => of(new UserActions.GetTariffFailed(err)))
            );
        })
    );

    @Effect()
    getTarffDiscount$: Observable<UserActions.UserActionsAll> = this.actions.pipe(
        ofType<UserActions.GetTariffDiscount>(UserActions.GET_TARIFF_DISCOUNT),
        switchMap((action) => {
            return this.http.get(`${this.urlApi}/tariff-discount/${action.payload}`).pipe(
                map((tariffs: any) => {
                    return new UserActions.GetTariffDiscountSuccess(tariffs.tariff_discounts);
                }),
                catchError((err) => of(new UserActions.GetTariffDiscountFailed(err)))
            );
        })
    );
}
