/* tslint:disable:variable-name */
export class Coverage
{
    id: number;
    group_id: number;
    status: number;
    coverage: number;
    vk_comments: number;
    vk_likes: number;
    vk_reposts: number;
    vk_reach_viral: number;
    vk_reach_ads: number;
    vk_reach_total: number;
    vk_links: number;
    vk_to_group: number;
    vk_join_group: number;
    vk_report: number;
    vk_hide: number;
    vk_unsubscribe: number;
    time: number;
    createdAt: string;
    updatedAt: string;

    constructor(data?: any)
    {
        data = data || {};
        this.id             = data.id;
        this.group_id       = data.group_id;
        this.status         = data.status;
        this.coverage       = this.helper(data.coverage);
        this.vk_comments    = this.helper(data.vk_comments);
        this.vk_likes       = this.helper(data.vk_likes);
        this.vk_reposts     = this.helper(data.vk_reposts);
        this.vk_reach_viral = this.helper(data.vk_reach_viral);
        this.vk_reach_ads   = this.helper(data.vk_reach_ads);
        this.vk_reach_total = this.helper(data.vk_reach_total);
        this.vk_links       = this.helper(data.vk_links);
        this.vk_to_group    = this.helper(data.vk_to_group);
        this.vk_join_group  = this.helper(data.vk_join_group);
        this.vk_report      = this.helper(data.vk_report);
        this.vk_hide        = this.helper(data.vk_hide);
        this.vk_unsubscribe = this.helper(data.vk_unsubscribe);
        this.time           = data.time;
        this.createdAt      = data.createdAt;
        this.updatedAt      = data.updatedAt;
    }

    helper(value: any): any
    {
        if (value === -1)
        {
            return null;
        }
        return value;
    }
}
