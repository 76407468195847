/* tslint:disable:variable-name */
import * as GroupActions from '../actions/group.action';
import { Category } from '../../models/category';
import { Group } from '../../models/group';
import { TextAttribute } from '@angular/compiler/src/render3/r3_ast';

export interface GroupsState
{
    groups?: {[vk_id: number]: Group};
    categories?: {[id: number]: Category};
    groupsLoaded: boolean;
    categoriesLoaded: boolean;
    vkGroups: [];
    loading: boolean;
    error: string;
    badVkToken: boolean;
}

export const GroupsInitialState: GroupsState = {
    groups          : {},
    categories      : {},
    groupsLoaded    : false,
    categoriesLoaded: false,
    vkGroups: [],
    loading: false,
    error: '',
    badVkToken: false
};

export function GroupReducer(state = GroupsInitialState, action: GroupActions.GroupActionsAll): GroupsState
{
    switch (action.type)
    {
        case GroupActions.GET_GROUPS:
            return {
                ...state,
                groupsLoaded: false
            };

        case GroupActions.UPDATE_GROUP:
        {
            let payload;

            if (typeof state.groups[action.payload.vk_id] === 'object')
            {
                payload = { ...state.groups[action.payload.vk_id], ...action.payload };
            }
            else
            {
                payload = action.payload;
            }
            return {
                ...state,
                groups: {
                    ...state.groups,
                    [payload.vk_id]: new Group(payload)
                }
            };
        }

        case GroupActions.GET_GROUPS_SUCCESS:
        {
            const groups = action.payload.reduce(
                (_entities: {[vk_id: number]: any}, group: any) =>
                {
                    return {
                        ..._entities,
                        [group.vk_id]: new Group(group)
                    };
                }, {});
            return {
                ...state,
                groupsLoaded: true,
                groups
            };
        }

        case GroupActions.GET_CATEGORIES_SUCCESS:
        {
            const categories = action.payload.reduce(
                (_entities: {[id: number]: any}, category: Category) =>
                {
                    return {
                        ..._entities,
                        [category.groups_category_id]: new Category(category)
                    };
                }, {});

            return {
                ...state,
                categoriesLoaded: true,
                categories
            };
        }

        case GroupActions.GET_CATEGORIES_FAILED:
            return {
                ...state,
                categoriesLoaded: false
            };

        case GroupActions.GET_GROUPS_FAILED:
            const { error } = action.payload;
            return {
                ...state,
                groupsLoaded: false,
                badVkToken: error.code === 0
            };

        case GroupActions.GET_VK_GROUP:
            return {
                ...state,
                loading: true,
            };
        case GroupActions.GET_VK_GROUP_SUCCESS:
            return {
                ...state,
                vkGroups: action.payload,
                loading: false,
            };

        case GroupActions.GET_VK_GROUP_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };

        case GroupActions.GROUP_ADD:
            return {
                ...state,
                loading: true,
            };

        case GroupActions.GROUP_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
            };

        case GroupActions.GROUP_ADD_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };

            case GroupActions.GROUP_GET_STATISTICS:
                return {
                    ...state,
                    loading: true,
                };

                case GroupActions.GROUP_GET_STATISTICS_SUCCESS:
                    return {
                        ...state,
                        loading: false,
                    };
    
        default:
            return state;
    }
}
