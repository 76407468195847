import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as AdminActions from '../actions/admin.action';

@Injectable()
export class AdminActionsEffects {
    constructor(private actions: Actions, private http: HttpClient) {}
    readonly urlApi: string = environment.urlApi;

    /**
     * Get Users from Server
     */
    @Effect()
    getUsers: Observable<AdminActions.AdminActionsAll> = this.actions.pipe(
        ofType<AdminActions.GetUsers>(AdminActions.GET_USERS),
        switchMap(() => {
            return this.http.get(`${this.urlApi}/admin/users`).pipe(
                map((data: any) => {
                    return new AdminActions.GetUsersSuccess(data);
                }),
                catchError((err) => of(new AdminActions.GetUsersFailed(err)))
            );
        })
    );

    /**
     * Get Tarifs from Server
     */
    @Effect()
    getTarifs: Observable<AdminActions.AdminActionsAll> = this.actions.pipe(
        ofType<AdminActions.GetTarifs>(AdminActions.GET_TARIFS),
        switchMap(() => {
            return this.http.get(`${this.urlApi}/tariff`).pipe(
                map((data: any) => {
                    return new AdminActions.GetTarifsSuccess(data);
                }),
                catchError((err) => of(new AdminActions.GetTarifsFailed(err)))
            );
        })
    );
}
