<div class="line">
    <div class="pimp">
        <span class="round" cdkFocusInitial>{{length}}</span>
    </div>
</div>

<div class="notification">
    <div>
        <div class="header">
            <div class="type" [ngClass]="currentNatif().type">
                {{natifType[currentNatif().type]}}
            </div>
        </div>
        <div class="message" [innerHTML]="currentNatif().description">
        </div>
        <div style="text-align: center;
        align-content: center;
        width: 100%;
        margin: 10px;
        float: left;
        margin: 1% 30%;
        position: relative;
        width: 50%;
        overflow: hidden;">
            <div id="vk_allow_messages_from_community"></div>
        </div>
        <div class="wrapper" [ngClass]="{'dark':isDark}" *ngIf="length>1">
            <span *ngIf="pageEvent">{{pageEvent.pageIndex +1 || 1}} из {{length}}</span>
            <span *ngIf="!pageEvent">1 из {{length}}</span>
            <mat-paginator style="background-color: transparent !important;" [length]="length" [pageSize]="pageSize"
                (page)="pageEvent = $event">
            </mat-paginator>
        </div>
    </div>
</div>