import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { environment } from 'environments/environment.prod';

@Component({
    selector: 'app-refresh-token-modal',
    templateUrl: './refresh-token-modal.component.html',
    styleUrls: ['./refresh-token-modal.component.scss'],
})
export class RefreshTokenModalComponent implements OnInit {
    private clientIdVk;
    private urlApi = environment.urlApi;

    @Input() titleText: string;
    @Input() bodyText: string;
    @Input() successButtonText: string;

    constructor(private _renderer: Renderer2, private _httpClient: HttpClient) {}

    ngOnInit(): void {
        this.clientIdVk = environment.clientIdVk;

        this._renderer.listen('window', 'OAuthCallback', (evt) => {
            // проверяем значение параметра state перед обработкой данных
            if (evt.detail.indexOf('state=refresh') !== -1) {
                const hash = evt.detail;
                const accessToken = hash.split('&')[0].split('=')[1];
                const userId = hash.split('&')[2].split('=')[1];
                const body = {
                    access_token: accessToken,
                    user_id: userId,
                };

                const header = new HttpHeaders({ 'Content-Type': 'application/json' });
                var options = { headers: header };

                this._httpClient
                    .post(`${this.urlApi}/change-social-profile`, body, options)
                    .subscribe(
                        (res) => {
                            window.location.reload();
                        },
                        (err) => {
                            // nothing here yet
                        }
                    );
            }
        });
    }

    refresh() {
        this.signInWithVk();
    }

    signInWithVk(): void {
        const width = 860;
        const height = 500;
        const left = screen.width / 2 - width / 2;
        const top = screen.height / 2 - height / 2;
        const windowOptions = `menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=${width}, height=${height}, top=${top}, left=${left}`;
        const type = 'auth';
        const url = window.location.href;
        const arr = url.split('/');
        const result = arr[0] + '//' + arr[2];
        const state = 'refresh'; // передаем дополнительные данные в параметре state

        let wdw = window.open(
            `https://oauth.vk.com/authorize?client_id=${this.clientIdVk}&display=page&scope=groups,stats,wall,offline,email&redirect_uri=${result}/oauth-callback.html&response_type=token&v=5.103&state=${state}`,
            type,
            windowOptions
        );
    }
}
