import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';

import { FuseNavigationComponent } from './navigation.component';
import { FuseNavVerticalItemComponent } from './vertical/item/item.component';
import { FuseNavVerticalCollapsableComponent } from './vertical/collapsable/collapsable.component';
import { FuseNavVerticalGroupComponent } from './vertical/group/group.component';
import { FuseNavHorizontalItemComponent } from './horizontal/item/item.component';
import { FuseNavHorizontalCollapsableComponent } from './horizontal/collapsable/collapsable.component';
import { GroupsService } from 'app/main/apps/groups/groups.service';
import { TariffService } from 'app/shared/services/tariff.service';
import { BannersService } from 'app/shared/services/banners.service';

@NgModule({
    imports: [CommonModule, RouterModule, MatIconModule, MatRippleModule],
    exports: [FuseNavigationComponent],
    declarations: [
        FuseNavigationComponent,
        FuseNavVerticalGroupComponent,
        FuseNavVerticalItemComponent,
        FuseNavVerticalCollapsableComponent,
        FuseNavHorizontalItemComponent,
        FuseNavHorizontalCollapsableComponent,
    ],
    providers: [GroupsService, TariffService, DatePipe, BannersService],
})
export class FuseNavigationModule {}
