import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';

import { navigation } from 'app/navigation/navigation';
import { Auth2Service } from './auth.service';
import { State } from './store/reducers';
import { Store, select } from '@ngrx/store';
import { GetAccount, GetProfile, GetTariff, GetTariffDiscount } from './store/actions/user.action';
import { getAccount, getLogin } from './store/selectors/user.selectors';
import { GroupsLoading } from './store/selectors/group.selectors';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NatificationModalComponent } from './shared/components/natification-modal/natification-modal.component';
import { getNatifications, getProfile } from 'app/store/selectors';
import { GetNatification } from './store/actions/natification.action';
import { LocalStorageService } from './shared/services/local-storage.service';
import * as Sentry from '@sentry/angular';
import { NavigationEnd, Router } from '@angular/router';
import { start } from 'repl';

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    fuseConfig: any;
    navigation: any;
    testnumber = 1000000000;
    natifRef: any = null;
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _fuseProgressBarService: FuseProgressBarService,
        private _platform: Platform,
        private _auth2Service: Auth2Service,
        private _store: Store<State>,
        private _modal: MatDialog,
        private readonly _storage: LocalStorageService,
        private _router: Router
    ) {
        let tmpAccountInfo = _storage.getAccountExtras();
        if (tmpAccountInfo) {
            Sentry.configureScope((scope) => {
                scope.setUser({
                    user_id: tmpAccountInfo.split('|')[0],
                    email: tmpAccountInfo.split('|')[1],
                });
            });
        }

        // Get default navigation
        this.navigation = navigation;

        // Register the navigation to the service
        this._fuseNavigationService.register('main', this.navigation);

        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation('main');

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile');
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        if (this._auth2Service.loggedIn) {
            this.getInfo();
            this._store.dispatch(new GetNatification());

            // Временно убираем запросы каждую минуту
            // this.getNatificationShedule();
        }

        this._store.pipe(select(getLogin)).subscribe((login) => {
            if (login) {
                this.getInfo();
            }
        });

        this._store.pipe(select(getNatifications)).subscribe((notifications) => {
            if (notifications.length && (this.natifRef == null || this.natifRef._state != 0)) {
                this.natifRef = this._modal.open(NatificationModalComponent, {
                    data: {
                        notifications: notifications,
                    },
                    panelClass: 'custom-modalbox',
                });
            } else if (this.natifRef) {
                this.natifRef.afterClosed().subscribe(() => {
                    this.natifRef = null;
                });
            }
        });

        this._router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                if (val.url != '/pages/auth/login') this._store.dispatch(new GetNatification());
            }
        });

        // Subscribe to config changes
        this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
            this.fuseConfig = config;

            // Boxed
            if (this.fuseConfig.layout.width === 'boxed') {
                this.document.body.classList.add('boxed');
            } else {
                this.document.body.classList.remove('boxed');
            }

            // Color theme - Use normal for loop for IE11 compatibility
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < this.document.body.classList.length; i++) {
                const className = this.document.body.classList[i];

                if (className.startsWith('theme-')) {
                    this.document.body.classList.remove(className);
                }
            }

            this.document.body.classList.add(this.fuseConfig.colorTheme);
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }
    getInfo(): void {
        this._store.dispatch(new GetProfile());
        this._store.dispatch(new GetAccount());

        this._store.pipe(select(getAccount)).subscribe((account) => {
            if (account.tariff_id) {
                this._store.dispatch(new GetTariffDiscount(account.tariff_id));
                this._store.dispatch(new GetTariff(account.tariff_id));
            }
        });
        this._store.pipe(select(GroupsLoading)).subscribe((laoding) => {
            if (laoding) {
                return this._fuseProgressBarService.show();
            }
            this._fuseProgressBarService.hide();
        });
    }

    getNatificationShedule() {
        setInterval(() => {
            if (!this.natifRef) {
                this._store.dispatch(new GetNatification());
            }
        }, 60000);
    }
}
