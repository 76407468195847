<mat-toolbar class="p-0 mat-elevation-z1">

    <div fxFlex
         fxFill
         fxLayout="row"
         fxLayoutAlign="start center">

        <div fxFlex="1 0 auto"
             fxLayout="row"
             fxLayoutAlign="start center">

            <button mat-icon-button
                    class="navbar-toggle-button"
                    *ngIf="!hiddenNavbar && !rightNavbar"
                    (click)="toggleSidebarOpen('navbar')"
                    fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

            <div class="toolbar-separator"
                 *ngIf="!hiddenNavbar && !rightNavbar"
                 fxHide.gt-md></div>

            <div fxLayout="row"
                 fxLayoutAlign="start center"
                 *ngIf="horizontalNavbar">
                <div class="logo ml-16">
                    <img class="logo-icon"
                         src="assets/images/logos/vk-panel-logo.svg">
                </div>
            </div>

            <div class="px-8 px-md-16">
                <fuse-shortcuts [navigation]="navigation"></fuse-shortcuts>
            </div>

        </div>

        <div class=""
             fxFlex="0 1 auto"
             fxLayout="row"
             fxLayoutAlign="start center">

            <ng-container *ngIf="(user$ | async) as user">
                <button mat-button
                        [matMenuTriggerFor]="userMenu"
                        class="user-button first">
                    <div fxLayout="row"
                         fxLayoutAlign="center center">
                        <img class="avatar mr-0 mr-sm-16"
                             [src]="user.photo ? user.photo : 'assets/img/no-avatar.jpg'">
                        <span class="username mr-12" *ngIf="user.first_name && user.last_name"
                              fxHide
                              fxShow.gt-sm>
                                {{user.first_name && user.first_name | truncate: 8}}
                                {{user.last_name && user.last_name | truncate: 7 : true}}
                            </span>
                        <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                    </div>
                </button>

                <mat-menu #userMenu="matMenu" [overlapTrigger]="false">

                    <button mat-menu-item [routerLink]="'/apps/profile/settings'">
                        <mat-icon>account_circle</mat-icon>
                        <span>Профиль</span>
                    </button>

                    <button (click)="logout()"
                            mat-menu-item
                            class="">
                        <mat-icon>exit_to_app</mat-icon>
                        <span>Выход</span>
                    </button>

                </mat-menu>

                <div class="toolbar-separator"></div>
            </ng-container>

            <button mat-button class="user-button" *ngIf="colorTheme === 'theme-default'"
                    (click)="selectTheme('theme-custom-dark')">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <mat-icon>brightness_2</mat-icon>
                </div>
            </button>

            <button mat-button class="user-button" *ngIf="colorTheme === 'theme-custom-dark'"
                    (click)="selectTheme('theme-default')">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <mat-icon>wb_sunny</mat-icon>
                </div>
            </button>

        </div>

    </div>

</mat-toolbar>