import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment.prod';

@Injectable()
export class TariffService {
    private urlApi: string;

    constructor(private http: HttpClient) {
        this.urlApi = environment.urlApi;
    }

    change(): Promise<any> {
        return this.http.get(this.urlApi + '/unset-trial').toPromise();
    }
}
