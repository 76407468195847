import { Injectable } from '@angular/core';
import { HttpService } from 'app/shared/services/http-service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NatificationService {

  public urlApi = environment.urlApi;

  constructor(
    private _httpClient: HttpService,
  ) { }


  setNatificationSettings(settings): Promise<any> {
    return this._httpClient
      .postRequest(`/notifications/settings`, settings)
      .toPromise();
  }

  /**
   * PUT VK notifications settings update
   * 
   * @param userId 
   * @param status 
   * @returns 
   */
  updateVkSettings(userId: number, status: boolean): Promise<any> {
    return this._httpClient
      .putRequest(`/account/vk-notifications`, {
        vk_notifications_enabled: status as boolean,
        vk_notification_user_id: userId as number,
      })
      .toPromise();
  }
}
