/* tslint:disable:variable-name */
import * as moment from 'moment';
import { MassFilter } from './mass-filter';
import { Coverage } from './coverage';
import { Filter } from './filter';
import { DatePipe } from '@angular/common';
import { ContentProtection } from './contentProtection';
import { MarketData } from './market-data';

export class Group {
    id: number;
    is_new: number;
    name: string;
    num_posts_scan: string;
    photo: string;
    status: number;
    tag: string;
    members_count:number;

    category_id: number;
    category: any;
    categroy_id: number;
    createdAt: string;
    created_at: string;
    content_protection: ContentProtection;

    updatedAt: string;
    user_id: number;
    vk_id: number;
    date_payment: string;
    del_stat: any;
    expired: boolean;
    mass_filters?: MassFilter;
    coverage?: Coverage;
    filter?: Filter;
    marketData?: MarketData;
    photo_50: string;
    photo_100: string;
    photo_200: string;
    autopay?: string;

    coverageValue: number = 0;
    coverageVkValue: number = 0;
    priceValue?: number;
    datePaymentFormat: string;
    isExpired: boolean;

    get isComplete(): boolean {
        return !!(this.filter.id || this.coverage.id || this.mass_filters.users_id);
    }

    checkExpired(): boolean {
        if (this.date_payment == null) {
            return true;
        }

        const currentDate = moment.utc(new Date()).utcOffset(0);
        const paymentDate = moment.utc(this.date_payment).utcOffset(0);

        return paymentDate.subtract(3, 'hours').isBefore(currentDate);
    }

    constructor(data?: any) {
        data = data || {};
        this.createdAt = data.createdAt;
        this.created_at = data.created_at;
        this.id = data.id;
        this.is_new = data.is_new;
        this.name = data.name;
        this.num_posts_scan = data.num_posts_scan;
        this.photo = data.photo;
        this.members_count = data.members_count;
        this.status = data.status;
        this.category = data.category || {};
        this.updatedAt = data.updatedAt;
        this.user_id = data.user_id;
        this.vk_id = data.vk_id;
        this.date_payment = data.date_payment;
        this.del_stat = data.del_stat;
        this.expired = data.expired;
        this.category_id = data.category_id;
        this.mass_filters = new MassFilter(data.mass_filters); // !!data.mass_filters ? new MassFilters(data.mass_filters) : null;
        this.coverage = new Coverage(data.coverage); // !!data.coverage ? new Coverage(data.coverage) : null;
        this.filter = new Filter(data.filter); // !!data.filter ? new Filter(data.filter) : null;
        this.marketData = new MarketData(data.marketData);

        if (this.marketData) {
            this.coverageVkValue = this.marketData.reach_subscriber
                ? this.marketData.reach_subscriber
                : 0;
        }
        if (this.marketData) {
            this.priceValue = this.marketData.price ? this.marketData.price : 0;
        }

        this.content_protection = new ContentProtection(data.content_protection);
        this.photo_50 = data.photo_50;
        this.photo_100 = data.photo_100;
        this.photo_200 = data.photo_200;
        this.autopay = data.autopay;
        if (!!this.date_payment) {
            this.datePaymentFormat = moment
                .utc(this.date_payment)
                .utcOffset(0)
                .format('DD.MM.YYYY HH:mm');
        }
        this.isExpired = this.checkExpired();
    }

    transform(value: string, timeZone: string = 'MSK'): string | null {
        if (value == null || timeZone == null || value == undefined || value == '') {
            return '';
        }

        // assuming that all inputs are coming in UTC0
        let date = value.split(' ')[0];
        let time = value.split(' ')[1];

        let dateInDetails = date.split('.');
        let timeInDetails = time.split(':');

        const mDate = new Date(
            Number(dateInDetails[2]),
            Number(dateInDetails[1]) - 1,
            Number(dateInDetails[0]),
            Number(timeInDetails[0]),
            Number(timeInDetails[1]),
            0
        );

        return new DatePipe('ru').transform(
            this.convertUTCDateToLocalDate(mDate),
            'dd.MM.yyyy HH:mm',
            timeZone == 'MSK' ? 'GMT+3' : ''
        );
    }

    convertUTCDateToLocalDate(date) {
        var newDate = new Date(date);
        var offset = date.getTimezoneOffset() / 60;
        var hours = date.getHours();
        var day = date.getDate() - 1;

        newDate.setHours(hours - offset);
        if (hours < offset) {
            if (day <= 0) {
                day = 1;
                var month = newDate.getMonth() - 1;
                if (month < 0) {
                    month = 0;
                    newDate.setFullYear(newDate.getFullYear() - 1);
                }
                newDate.setMonth(month);
            }
            newDate.setDate(day);
        }
        return newDate;
    }

    public setProtectionProps(data: any): void {
        if (data == null) {
            this.content_protection = null;
        } else {
            this.content_protection = new ContentProtection(data);
        }
    }
}
