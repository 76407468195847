import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { hmrBootstrap } from 'hmr';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';

Sentry.init({
    dsn: 'https://35c69f8b8440450a9093ac4f9f23a3c7@sentry.vk-panel.ru/2',

    // integrations: [
    //     new Integrations.BrowserTracing({
    //         tracingOrigins: ['localhost', environment.urlApi],
    //         routingInstrumentation: Sentry.routingInstrumentation,
    //     }),
    // ],

    integrations(integrations) {
        return integrations.filter((integration) => integration.name !== 'Breadcrumbs');
    },

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
});

if (environment.production) {
    enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.hmr) {
    if (module['hot']) {
        hmrBootstrap(module, bootstrap);
    } else {
        console.error('HMR is not enabled for webpack-dev-server!');
        console.log('Are you using the --hmr flag for ng serve?');
    }
} else {
    bootstrap().catch((err) => console.error(err));
}
