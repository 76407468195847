import { ActivatedRouteSnapshot, RouterStateSnapshot, Params } from '@angular/router';
import { createFeatureSelector, ActionReducerMap } from '@ngrx/store';
import * as fromRouter from '@ngrx/router-store';
import { Injectable } from '@angular/core';
import { UserReducer, UserState } from './user.reducer';
import { GroupReducer, GroupsState } from './group.reducer';
import { NatificationReducer, NatificationState } from './natification.reducer';
import { AdminActionsReducer, AdminActionsState } from './admin.reducer';

export interface RouterStateUrl
{
    url: string;
    queryParams: Params;
    params: Params;
}

export interface State
{
    routerReducer: fromRouter.RouterReducerState<RouterStateUrl>;
    userReducer: UserState;
    groupReducer: GroupsState;
    natificationReducer: NatificationState
    adminActionsReducer: AdminActionsState
}

export const reducers: ActionReducerMap<State> = {
    routerReducer: fromRouter.routerReducer,
    userReducer  : UserReducer,
    groupReducer : GroupReducer,
    natificationReducer: NatificationReducer,
    adminActionsReducer: AdminActionsReducer
};

export const getAdminActionsState = createFeatureSelector<AdminActionsState>('adminActionsReducer');
export const getUserState   = createFeatureSelector<UserState>('userReducer');
export const getGroupsState = createFeatureSelector<GroupsState>('groupReducer');
export const getRouterState = createFeatureSelector<fromRouter.RouterReducerState<RouterStateUrl>>('routerReducer');
export const getNatificationState = createFeatureSelector<NatificationState>('natificationReducer');


@Injectable()
export class CustomSerializer implements fromRouter.RouterStateSerializer<RouterStateUrl>
{
    serialize(routerState: RouterStateSnapshot): RouterStateUrl
    {
        const { url }         = routerState;
        const { queryParams } = routerState.root;

        let state: ActivatedRouteSnapshot = routerState.root;
        while (state.firstChild)
        {
            state = state.firstChild;
        }
        const { params } = state;

        return {
            url,
            queryParams,
            params
        };
    }
}
