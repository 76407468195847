import { createSelector } from '@ngrx/store';
import { getAdminActionsState } from '../reducers';
import { AdminActionsState } from '../reducers/admin.reducer';

// Users

export const getUsers = createSelector(
    getAdminActionsState,
    (state: AdminActionsState) => state.users
);

export const getUsersLoaded = createSelector(
    getAdminActionsState,
    (state: AdminActionsState) => state.usersLoaded
);

export const getUsersArr = createSelector(getUsers, (entities) =>
    Object.keys(entities).map((id) => entities[id])
);

export const UsersLoading = createSelector(
    getAdminActionsState,
    (state: AdminActionsState) => state.loading
);

// Tarifs

export const getTarifs = createSelector(
    getAdminActionsState,
    (state: AdminActionsState) => state.tarifs
);

export const getTarifsLoaded = createSelector(
    getAdminActionsState,
    (state: AdminActionsState) => state.tarifsLoaded
);

export const getTarifsArr = createSelector(getTarifs, (entities) =>
    Object.keys(entities).map((id) => entities[id])
);

export const TarifsLoading = createSelector(
    getAdminActionsState,
    (state: AdminActionsState) => state.loading
);
