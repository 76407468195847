import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import * as GroupActions from '../actions/group.action';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RefreshTokenModalComponent } from 'app/shared/components/refresh-token-modal/refresh-token-modal.component';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';

@Injectable()
export class GroupEffects {
    constructor(
        private actions: Actions,
        private http: HttpClient,
        private matSnackBar: MatSnackBar,
        private modal: MatDialog,
        private _fuseProgressBarService: FuseProgressBarService,
    ) {}
    readonly urlApi: string = environment.urlApi;
    /**
     * Get Groups from Server
     */
    @Effect()
    getGroups: Observable<GroupActions.GroupActionsAll> = this.actions.pipe(
        ofType<GroupActions.GetGroups>(GroupActions.GET_GROUPS),
        switchMap(() => {
            return this.http.get(`${this.urlApi}/group?limit=1000`).pipe(
                map((data: any) => {
                    return new GroupActions.GetGroupsSuccess(data);
                }),
                catchError((err) => of(new GroupActions.GetGroupsFailed(err)))
            );
        })
    );

    @Effect({ dispatch: false })
    getGroupsFailed$ = this.actions.pipe(
        ofType(GroupActions.GET_GROUPS_FAILED),
        map((action: any) => action.payload),
        tap((action) => {
            let message: string = '';
            if (action.error.code === 0) {
                // message = 'Перепривяжите аккаунт вк!';
                // const modalRef = this.modal.open(RefreshTokenModalComponent, {
                //     disableClose: true,
                // });
                // (<RefreshTokenModalComponent>modalRef.componentInstance).titleText =
                //     'VK-токен недоступен';
                // (<RefreshTokenModalComponent>modalRef.componentInstance).bodyText =
                //     'Пройдите процедуру логина для обновления токена.';
                // (<RefreshTokenModalComponent>modalRef.componentInstance).successButtonText =
                //     'Обновить';
                // this._fuseProgressBarService.hide();
            } else {
                if (action.status == 500) return;
                message = action.error.message;
                this.matSnackBar.open(message, 'OK', {
                    horizontalPosition: 'center',
                    duration: 5000,
                });
            }
        })
    );

    /**
     * Get Group from Server
     */
    @Effect()
    getGroup: Observable<GroupActions.GroupActionsAll> = this.actions.pipe(
        ofType<GroupActions.GetGroup>(GroupActions.GET_GROUP),
        switchMap((action) => {
            return this.http.get(`${this.urlApi}/group/` + action.id).pipe(
                map((data: any) => {
                    return new GroupActions.UpdateGroup(data);
                }),
                catchError((err) => of(new GroupActions.GetGroupFailed(err)))
            );
        })
    );

    /**
     * Get Categories from Server
     */
    @Effect()
    getCategories: Observable<GroupActions.GroupActionsAll> = this.actions.pipe(
        ofType<GroupActions.GetCategories>(GroupActions.GET_CATEGORIES),
        switchMap(() => {
            return this.http.get(`${this.urlApi}/group/category`).pipe(
                map((data: any[]) => {
                    return new GroupActions.GetCategoriesSuccess(data);
                }),
                catchError((err) => of(new GroupActions.GetCategoriesFailed(err)))
            );
        })
    );

    @Effect()
    GetVKGroup$: Observable<GroupActions.GroupActionsAll> = this.actions.pipe(
        ofType<GroupActions.GetVKGroup>(GroupActions.GET_VK_GROUP),
        switchMap(() => {
            return this.http.get(`${this.urlApi}/vk-group`).pipe(
                map((data: { items: any[] }) => {
                    return new GroupActions.GetVKGroupSuccess(data.items);
                }),
                catchError((err) => of(new GroupActions.GetVKGroupFailed(err)))
            );
        })
    );

    @Effect()
    GroupAdd$: Observable<GroupActions.GroupActionsAll> = this.actions.pipe(
        ofType<GroupActions.GroupAdd>(GroupActions.GROUP_ADD),
        switchMap((action) => {
            return this.http.post(`${this.urlApi}/group`, action.payload).pipe(
                map(() => {
                    return new GroupActions.GroupAddSuccess();
                }),
                catchError((err) => of(new GroupActions.GroupAddFailed(err.error.message)))
            );
        })
    );

    @Effect({ dispatch: false })
    GroupAddSuccess$ = this.actions.pipe(
        ofType(GroupActions.GROUP_ADD_SUCCESS),
        tap(() => {
            this.matSnackBar.open('Группа успешно добавлена!', 'OK', {
                verticalPosition: 'top',
                duration: 4000,
            });
        })
    );

    @Effect({ dispatch: false })
    GroupAddFail$ = this.actions.pipe(
        ofType(GroupActions.GROUP_ADD_FAILED),
        map((action: GroupActions.GroupAddFailed) => action.payload),
        tap((action) => {
            this.matSnackBar.open(action, 'OK', {
                verticalPosition: 'top',
                duration: 2000,
            });
        })
    );
}
