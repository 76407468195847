/* tslint:disable */
export class Filter
{
    id: number;
    uid: number;
    gid: number;
    fid: number;
    filtre_name: string;
    exception: string;
    minutes: number;
    type: number;
    is_marked_as_ads: number;
    is_copy_history: number;
    is_marked_as_ads_time: number;
    is_marked_as_ads_remove: number;
    is_copy_history_time: number;
    is_copy_history_remove: number;
    is_not_remove: number;
    is_attached: number;
    is_attached_time: number;
    is_attached_remove: number;
    is_audio: number;
    is_audio_time: number;
    is_audio_remove: number;
    is_video: number;
    is_video_time: number;
    is_video_remove: number;
    is_wiki: number;
    is_wiki_time: number;
    is_wiki_remove: number;
    is_source: number;
    is_source_time: number;
    is_source_remove: number;
    is_doc: number;
    is_doc_time: number;
    is_doc_remove: number;
    is_poll: number;
    is_poll_time: number;
    is_poll_remove: number;
    is_good: number;
    is_good_time: number;
    is_good_remove: number;
    is_attached_order: number;
    is_audio_order: number;
    is_video_order: number;
    is_wiki_order: number;
    is_doc_order: number;
    is_poll_order: number;
    is_good_order: number;
    is_audio_title: string;
    is_audio_artist: string;
    is_video_title: string;
    is_pinned: number;
    createdAt: string;
    updatedAt: string;

    constructor(data?: any)
    {
        data                         = data || {};
        this.id                      = data.id;
        this.uid                     = data.uid;
        this.gid                     = data.gid;
        this.fid                     = data.fid;
        this.filtre_name             = data.filtre_name;
        this.exception               = data.exception;
        this.minutes                 = data.minutes;
        this.type                    = data.type;
        this.is_marked_as_ads        = data.is_marked_as_ads;
        this.is_copy_history         = data.is_copy_history;
        this.is_marked_as_ads_time   = data.is_marked_as_ads_time;
        this.is_marked_as_ads_remove = data.is_marked_as_ads_remove;
        this.is_copy_history_time    = data.is_copy_history_time;
        this.is_copy_history_remove  = data.is_copy_history_remove;
        this.is_not_remove           = data.is_not_remove;
        this.is_attached             = data.is_attached;
        this.is_attached_time        = data.is_attached_time;
        this.is_attached_remove      = data.is_attached_remove;
        this.is_audio                = data.is_audio;
        this.is_audio_time           = data.is_audio_time;
        this.is_audio_remove         = data.is_audio_remove;
        this.is_video                = data.is_video;
        this.is_video_time           = data.is_video_time;
        this.is_video_remove         = data.is_video_remove;
        this.is_wiki                 = data.is_wiki;
        this.is_wiki_time            = data.is_wiki_time;
        this.is_wiki_remove          = data.is_wiki_remove;
        this.is_source               = data.is_source;
        this.is_source_time          = data.is_source_time;
        this.is_source_remove        = data.is_source_remove;
        this.is_doc                  = data.is_doc;
        this.is_doc_time             = data.is_doc_time;
        this.is_doc_remove           = data.is_doc_remove;
        this.is_poll                 = data.is_poll;
        this.is_poll_time            = data.is_poll_time;
        this.is_poll_remove          = data.is_poll_remove;
        this.is_good                 = data.is_good;
        this.is_good_time            = data.is_good_time;
        this.is_good_remove          = data.is_good_remove;
        this.is_attached_order       = data.is_attached_order;
        this.is_audio_order          = data.is_audio_order;
        this.is_video_order          = data.is_video_order;
        this.is_wiki_order           = data.is_wiki_order;
        this.is_doc_order            = data.is_doc_order;
        this.is_poll_order           = data.is_poll_order;
        this.is_good_order           = data.is_good_order;
        this.is_audio_title          = data.is_audio_title;
        this.is_audio_artist         = data.is_audio_artist;
        this.is_video_title          = data.is_video_title;
        this.is_pinned               = data.is_pinned;
        this.createdAt               = data.createdAt;
        this.updatedAt               = data.updatedAt;
    }
}
