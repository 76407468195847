import { NgModule } from '@angular/core';
import { TruncatePipe } from '../cut-string.pipe';


@NgModule({
  imports: [],
  exports: [TruncatePipe],
  declarations: [TruncatePipe],
})
export class TurncateModule { }
