<div class="navbar-header" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">

    <div class="logo">
        <img class="logo-icon" src="assets/images/logos/vk-panel-logo.svg">
        <!-- <span class="logo-text">VK-PANEL.RU</span> -->
    </div>

    <button mat-icon-button class="toggle-sidebar-folded"
            (click)="toggleSidebarFolded()" fxHide.lt-lg>
        <mat-icon>menu</mat-icon>
    </button>

    <button mat-icon-button class="toggle-sidebar-opened"
            (click)="toggleSidebarOpened()" fxHide.gt-md>
        <mat-icon>arrow_back</mat-icon>
    </button>

</div>

<div class="navbar-content" fusePerfectScrollbar [fusePerfectScrollbarOptions]="{suppressScrollX: true}"
     [ngClass]="fuseConfig.layout.navbar.primaryBackground">

    <!-- <div class="payment-info border-bottom p-16" fxLayout="column">
        <div class="h5 hint-text mb-12 text-center">
            Дата ближайшего платежа:<br> "paymentDate}}
        </div>

        <button mat-raised-button class="amber-bg fuse-black-fg font-weight-400" [routerLink]="'/apps/finance'">
            Пополнить баланс на {{this.tariff.price}}руб.
        </button>
    </div> -->
    <!-- <div class="payment-info-folded">
        <div [routerLink]="'/apps/finance'" class="amber-fg">
            <mat-icon class="s-16" >attach_money</mat-icon>
        </div>
    </div> -->

    <fuse-navigation layout="vertical" [isMobile]="isMobile"></fuse-navigation>
</div>